import React from 'react';
import { Grid, makeStyles, createStyles, Typography } from '@material-ui/core';

import DoctorsPng from '../../Images/doctors.png';

interface Props {
  imageFile: any;
  imageText: string;
  [x: string]: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    text: {
      fontWeight: 'bold',
      fontSize:"20px"

    },
  }),
);

const PatientNotFound: React.FC<Props> = ({
  imageText,
  children,
}) => {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      container
      justify="center"
      direction="column"
      alignItems="center"
      spacing={3}
      style={{marginLeft:'200px',marginBottom:'500px',width:'500px' }}
    >
      <Grid item xs >
        <img src={DoctorsPng} alt="doctors"  width='150px'/>
      </Grid>
      <Grid item xs={12} sm={6} md={5}>
        <Typography
          className={classes.text}
          variant="h4"
          component="p"
          align="center"
        >
          {imageText}
        </Typography>
      </Grid>
      <Grid item container justify="space-around" xs={12} sm={9} md={9} lg={6}>
        {children}
      </Grid>
    </Grid>
  );
};

export default PatientNotFound;
