import React, { useState, useEffect, useContext } from 'react';
import {
  Grid,
  FormControlLabelProps,
  RadioProps,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import FirebaseUtil from '../../../config/firebase';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Field, FormikTouched, FormikErrors } from 'formik';
import clsx from 'clsx';
import RadioWithLabel from '../../../components/RadioWithLabel';
import { UserContext } from '../../../context/UserProvider';

import {
  AddressPickerWithGoogle,
  CheckBoxWithLabel,
  FormikAddressForm,
  UserAddressSelector,
} from '../../../components/';

interface Address {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zipCode: string;
  default?: boolean;
}

interface Props {
  className?: any;
  formControlLabelProps?: FormControlLabelProps;
  radioProps?: RadioProps;
  touched: FormikTouched<any> | any;
  errors: FormikErrors<any> | any;
  values: any;
  setValues: (values: any, shouldValidate?: boolean | undefined) => void;
  setFieldValue: any;
}
const useStyles = makeStyles(() =>
  createStyles({
    MUILimitation: {
      padding: '40px !important',
    },
    noAddressesTxt: {
      fontWeight: 'normal',
      marginTop: '2rem',
    },
  }),
);

const fetchUserData = (userId: string) => {
  return FirebaseUtil.firestore.collection('users').doc(userId).get();
};

const AddressForm: React.FC<Props> = ({
  className,
  values,
  errors,
  touched,
  setValues,
  setFieldValue,
}) => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs'),
  );

  useEffect(() => {
    (async () => {
      const userData = await fetchUserData((user as any).id);
      const fetchedAddresses = (userData.data() as any).addresses;
      if (fetchedAddresses) {
        setAddresses(fetchedAddresses);
      } else setAddresses([]);
    })();
  }, [user]);

  const { addressPick } = values;
  return (
    <div >
      <Grid container justify={"center"}>
        <Grid item xs={11} md={11} lg={11} xl={11}  >
{/*          <Grid item xs={12} >
            <Grid
              container
              justify={isSmallScreen ? 'flex-start' : 'space-around'}
            >
              <Grid item xs={6}>
                <Field
                  type="radio"
                  name="addressPick"
                  value="existingAddress"
                  color="primary"
                  label="Existing Addresses"
                  as={RadioWithLabel}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  type="radio"
                  name="addressPick"
                  value="newAddress"
                  color="primary"
                  label="New Address"
                  as={RadioWithLabel}
                />
              </Grid>
            </Grid>
          </Grid>*/}
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs>
{/*                {addressPick === 'existingAddress' ? (
                  addresses.length !== 0 ? (
                    <div style={{marginTop:'20px'}}>
                      <UserAddressSelector
                        addresses={addresses}
                        values={values}
                        setValues={setValues}
                      />
                      <Field
                        as={CheckBoxWithLabel}
                        name="default"
                        checked={values.default}
                        label="Save as default address."
                      />
                    </div>
                  ) : (
                    <Typography
                      className={classes.noAddressesTxt}
                      component="p"
                      variant="h5"
                    >
                      You don't have existing addresses yet
                    </Typography>
                  )
                ) : (*/}
                  <Grid container >
                    <Grid item xs={12}>
                      <AddressPickerWithGoogle
                        values={values}
                        setValues={setValues}
                      />
                    </Grid>
                    <Grid item xs={12}><div style={{height:'10px'}}/></Grid>
                    <Grid item xs={12}>
                      <FormikAddressForm
                        values={values}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
{/*                    <Grid item xs={12}>
                      <Grid item xs>
                        <Field
                          as={CheckBoxWithLabel}
                          checked={values.default}
                          name="default"
                          label="Save as default address."
                        />
                      </Grid>
                      <Grid item xs>
                        <Field
                          as={CheckBoxWithLabel}
                          checked={values.saveAddress}
                          name="saveAddress"
                          label="Save to my list of addresses."
                        />
                      </Grid>
                    </Grid>*/}
                  </Grid>
{/*                )}*/}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddressForm;
