import firebase from 'firebase/app';
import config from './index';
import 'firebase/storage';

import 'firebase/firestore';
import 'firebase/auth';
console.warn('config.firebase:',config.firebase);

class FirebaseUtil {
  firestore: firebase.firestore.Firestore;
  auth: firebase.auth.Auth;
  storage: firebase.storage.Storage;

  constructor() {
    firebase.initializeApp(config.firebase);
    this.firestore = firebase.firestore();
    this.auth = firebase.auth();
    this.storage = firebase.storage();
  }

  signUp = (email: string, password: string) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  signIn = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password);

  signOut = () => this.auth.signOut();

  forgotPassword = (email: string) => {
    this.auth.sendPasswordResetEmail(email);
  };
  init = () => {
    this.firestore.collection('invoices').doc('--stats--').set({
      invoiceCount: 0,
    });
  };
  findUser = (uid: string) => this.firestore.doc(`users/${uid}`);
}
export const firestore = firebase.firestore;
export default new FirebaseUtil();
