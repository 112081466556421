import * as yup from 'yup';

const schema = yup.object({
  address1: yup.string().required("Address line 1 is a required field"),
  address2: yup.string(),
  state: yup.string().required("State is a required field"),
  city: yup.string().required("City is a required field"),
  default: yup.boolean().default(false),
  zipCode: yup.string().max(5, 'Please enter a valid Zip Code').required("ZIP code is a required field"),
});

export default schema;
