import React from 'react';

import ReactPDF, { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  labelTextWrapper: {
    flexDirection: 'row',
  },
});

export interface Props {
  name?: string;
  address1: string;
  address2?: string;
  phone?: string;
  email?: string;
  website?: string;
  style?: ReactPDF.Style;
}
const PDFAddressData: React.FC<Props> = ({
  name,
  address1,
  address2,
  phone,
  email,
  website,
  style,
}) => {
  return (
    <View style={style}>
      {name && <Text>{name}</Text>}
      <Text>{address1}</Text>
      <Text>{address2}</Text>
      {phone && (
        <View style={styles.labelTextWrapper}>
          <Text>Phone: </Text>
          <Text>{phone}</Text>
        </View>
      )}
      {email && (
        <View style={styles.labelTextWrapper}>
          <Text style={{fontWeight:"bold"}}>Email: </Text>
          <Text>{email}</Text>
        </View>
      )}
      {website && (
        <View style={styles.labelTextWrapper}>
          <Text style={{fontWeight:"bold"}}>Website: </Text>
          <Text>{website}</Text>
        </View>
      )}
    </View>
  );
};

export default PDFAddressData;
