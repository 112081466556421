import React, {useCallback, useEffect, useState} from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {
     Dialog, DialogActions, DialogContent, DialogContentText,
     IconButton,
     Paper,
     Table,
     TableBody,
     TableCell,
     TableContainer,
     TableHead,
     TableRow,
     Typography,
} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Grid, InputAdornment, Button} from '@material-ui/core';
import {CustomButton, CustomInput} from '../../components';
import FirebaseUtil from '../../config/firebase';
import {useSnackbar} from 'notistack';
import axios from 'axios';
import firebase from 'firebase';
import config from '../../config';
import comonSnackbarOpts from '../../utilities/comonSnackBarOpt';

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
        tableHeader: {
             backgroundColor: theme.palette.primary.main,
        },
        tableHeaderText: {
             color: theme.palette.primary.contrastText,
        },
        Typography: {
             marginTop: '30px',
        },
        table: {
             Width: 400,
        },
        Button: {
             width: '200px',
             height: '55px',
        },
   }),
);

const Admin: React.FC = () => {
     const classes = useStyles();
     const {enqueueSnackbar} = useSnackbar();
     const [admin, setAdmin] = useState<any>('');
     const [email, setEmail] = useState<any>('');
     const [ad, setAd] = useState(null);
     const [isSubmitting, setIsSubmitting] = useState(false);
     const [isDeleting, setIsDeleting] = useState(false);
     const [openDelete, setOpenDelete] = React.useState(false);
     let CUser = firebase.auth().currentUser;
     useEffect(() => {
          (async () => {

               const adminData = await fetchAdmin();
               setAdmin(adminData);
          })();
     }, []);

     const fetchAdmin = useCallback(async () => {
          const adminRef = await FirebaseUtil.firestore
             .collection('admins')
             .get();
          return adminRef.docs.map(doc => doc.data());
     }, []);

     const handleAddAdmin = () => {
          setIsSubmitting(true);
          if (!email) {
               setIsSubmitting(false);
               return enqueueSnackbar('Please fill in all the required fields', {
                    variant: 'error',
                    ...comonSnackbarOpts,
               });
          }
          let user = firebase.auth().currentUser;
          axios.post(`${config.baseUrl}/setAsAdmin`, {
               adminEmail: user?.email,
               isAdmin: false,
               email: email,
          })
             .then(async () => {
                  enqueueSnackbar('Admin has been added successfully', {
                       variant: 'success',
                       ...comonSnackbarOpts,
                  });
                  const adminData = await fetchAdmin();
                  setEmail('');
                  setAdmin(adminData);
                  setIsSubmitting(false);
             })
             .catch(function() {
                  enqueueSnackbar(
                     'The user is not registered in the system yet. User must first register using the website',
                     {
                          variant: 'error',
                          ...comonSnackbarOpts,
                     });
                  setIsSubmitting(false);
                  setEmail('');

             });
     };
     const handleClickOpenDelete = (A: any) => {
          setOpenDelete(true);
          setAd(A);
     };

     const handleCloseDelete = () => {
          setOpenDelete(false);
     };
     const handleDeleteAdmin = () => {
          setIsDeleting(true);
          let user = firebase.auth().currentUser;
          axios.post(`${config.baseUrl}/setAsAdmin`, {
               adminEmail: user?.email,
               isAdmin: true,
               email: ad,
          })
             .then(async () => {
                  enqueueSnackbar('Admin has been removed', {
                       variant: 'success',
                       ...comonSnackbarOpts,
                  });
                  const adminData = await fetchAdmin();
                  setAdmin(adminData);
                  setOpenDelete(false);
                  setIsDeleting(false);
             })
             .catch(function(error) {
                  setIsDeleting(false);
                  console.log(error);
             });

     };

     return (
        <div>
             <Typography variant="h6" component="h3">
                  Add Admin
             </Typography>
             <br/>
             <Grid item container xs={12} spacing={3}>
                  <Grid item xs={6}>
                       <CustomInput
                          fullWidth
                          variant="filled"
                          name="email"
                          label="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="text"
                       />
                  </Grid>
                  <Grid item xs={4}>
                       <CustomButton
                          className={classes.Button}
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          size="small"
                          variant="contained"
                          onClick={handleAddAdmin}
                          color="primary"
                       >
                            Add Admin
                       </CustomButton>
                  </Grid>
             </Grid>
             <Typography variant="h6" component="h3" className={classes.Typography}>
                  Viewing all Admins
             </Typography>
             <br/>
             <TableContainer component={Paper}>
                  <Table className={classes.table}>
                       <TableHead>
                            <TableRow className={classes.tableHeader}>
                                 <TableCell className={classes.tableHeaderText} align="center">
                                      No.
                                 </TableCell>
                                 <TableCell className={classes.tableHeaderText} align="left">
                                      Admin Email
                                 </TableCell>
                                 <TableCell className={classes.tableHeaderText} align="left">
                                      Action
                                 </TableCell>
                            </TableRow>
                       </TableHead>
                       <TableBody>
                            {admin && admin.map((A: any, index: number) => (

                               <TableRow key={A.id}>
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell align="left"> {A.email}</TableCell>
                                    <TableCell align="left">
                                         {A.userId !== CUser?.uid ?
                                            <IconButton onClick={() => {
                                                 handleClickOpenDelete(A.email);
                                            }}
                                            >
                                                 <DeleteIcon/>
                                                 <Dialog
                                                    open={openDelete}
                                                    onClose={handleCloseDelete}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                 >
                                                      <DialogContent>
                                                           <DialogContentText
                                                              id="alert-dialog-description">
                                                                Are you sure you want to delete this
                                                                admin ?
                                                           </DialogContentText>
                                                      </DialogContent>
                                                      <DialogActions>
                                                           <CustomButton onClick={handleDeleteAdmin}
                                                                         color="primary"
                                                                         loading={isDeleting}
                                                                         disabled={isDeleting}
                                                           >
                                                                yes
                                                           </CustomButton>
                                                           <Button onClick={handleCloseDelete}
                                                                   color="primary" autoFocus
                                                                   disabled={isDeleting}>
                                                                No
                                                           </Button>
                                                      </DialogActions>
                                                 </Dialog>
                                            </IconButton>
                                            :
                                            null
                                         }

                                    </TableCell>

                               </TableRow>

                            ))}
                       </TableBody>
                  </Table>
             </TableContainer>

        </div>

     );
};
export default Admin;