import React from 'react';
import { Field, FormikTouched, FormikErrors } from 'formik';
import { MenuItem, Grid, Theme } from '@material-ui/core';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import CustomInput from '../../../components/CustomInput';
import { CustomSelect } from '../../../components';
import { PatientStepFormValues } from '../index';
import { formatPhoneNo } from '../../../utilities/phoneNoFormat';

interface Props {
  values: PatientStepFormValues;
  setFieldValue: any;
  errors: FormikErrors<PatientStepFormValues>;
  touched: FormikTouched<PatientStepFormValues>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    MUILimitation: {
      padding: '40px',
    },
    selectWrapper: {
      minHeight: '89px',
    },
    selectInput: {
      border: '1px solid #ccc',
      borderRadius: '3px',
    },
    textInput: {
      minHeight: '82px',
    },
    btnsContainer: {
      marginTop: '1.5rem',
      display: 'flex',
      justifyContent: 'space-between',
    },
    firstContainer: {
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '0 !important',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0 !important',
      },
    },
    secondContainer: {
      [theme.breakpoints.down('xs')]: {
        padding: '0 !important',
      },
    },
    submitBtnWrapper: {
      display: 'flex',
      padding: '2rem 0',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center !important',
      },
    },
  }),
);

const StatusForm: React.FC<Props> = ({
  errors,
  touched,
  values,
  setFieldValue,
}) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.MUILimitation}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6} className={classes.firstContainer}>
            <Grid container spacing={1}>
              <Grid item xs={12} className={classes.selectWrapper}>
                <Field
                  fullWidth
                  name="race"
                  type="select"
                  label="Race *"
                  as={CustomSelect}
                  error={touched.race && errors.race && true}
                  helperText={touched.race && errors.race}
                >
                  <MenuItem value="White, non-Hispanic">
                    White, non-Hispanic
                  </MenuItem>
                  <MenuItem value="hispanic">Hispanic</MenuItem>
                  <MenuItem value="latino">Latino</MenuItem>
                  <MenuItem value="Black or African Americans">
                    Black or African Americans
                  </MenuItem>

                  <MenuItem value="Asian">Asian</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                  <MenuItem value="Prefer not to specify">
                    Prefer not to specify
                  </MenuItem>
                </Field>
              </Grid>
              <Grid item xs={12} className={classes.selectWrapper}>
                <Field
                  fullWidth
                  name="martialStatus"
                  type="select"
                  label="Marital Status *"
                  as={CustomSelect}
                  error={touched.martialStatus && errors.martialStatus && true}
                  helperText={touched.martialStatus && errors.martialStatus}
                >
                  <MenuItem value="married">Married</MenuItem>
                  <MenuItem value="single">Single</MenuItem>
                  <MenuItem value="divorced">Divorced</MenuItem>
                  <MenuItem value="widowed">Widowed</MenuItem>
                </Field>
              </Grid>
              <Grid item xs={12} className={classes.selectWrapper}>
                <Field
                  fullWidth
                  name="prefaredLang"
                  type="select"
                  label="Preferred Language *"
                  as={CustomSelect}
                  error={touched.prefaredLang && errors.prefaredLang && true}
                  helperText={touched.prefaredLang && errors.prefaredLang}
                >
                  <MenuItem value="english">English</MenuItem>
                  <MenuItem value="spanish">Spanish</MenuItem>
                </Field>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.secondContainer}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Field
                  className={classes.textInput}
                  fullWidth
                  onChange={(e: any) => {
                    const { value } = e.target;
                    setFieldValue('cellPhone', formatPhoneNo(value));
                  }}
                  value={values.cellPhone}
                  name="cellPhone"
                  as={CustomInput}
                  label="Cell Phone *"
                  variant="filled"
                  error={touched.cellPhone && errors.cellPhone && true}
                  helperText={touched.cellPhone && errors.cellPhone}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  className={classes.textInput}
                  fullWidth
                  onChange={(e: any) => {
                    const { value } = e.target;
                    setFieldValue('homePhone', formatPhoneNo(value));
                  }}
                  value={values.homePhone}
                  name="homePhone"
                  as={CustomInput}
                  label="Home Phone"
                  variant="filled"
                  error={touched.homePhone && errors.homePhone && true}
                  helperText={touched.homePhone && errors.homePhone}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default StatusForm;
