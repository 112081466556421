import React, {useCallback, useEffect, useState} from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {useHistory, useParams} from 'react-router-dom';
import FirebaseUtil from '../../config/firebase';
import PatientsNotFound from '../../components/PatientsNotFound';
import DoctorsPng from '../../Images/doctors.png';
import {formatName} from '../../utilities/formatName';
import {fireStoreTimeStampToMUIDate} from '../../utilities/firestoreDates';
import {DASHBOARD, INVOICES} from '../UserDashboard/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      tableHeader: {
        backgroundColor: theme.palette.primary.main,
      },
      tableHeaderText: {
        color: theme.palette.primary.contrastText,
      },

      table: {
        minWidth: '666px',
      },
      tableBtn: {
        width: '100px',
      },
    }),
);

const ViewInvoices: React.FC = () => {
  const [invoices, setInvoices] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const {id} = useParams();
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      const invoiceData = await fetchInvoices();
      setInvoices(invoiceData);
      setLoading(false);
    })();
  }, []);

  const fetchInvoices = useCallback(async () => {
    const invoicesRef = await FirebaseUtil.firestore
        .collection('invoices')
        .where('patientId', '==', id)
        .get();
    return invoicesRef.docs
        .map(doc => {
          if (doc.id === '--stats--') return undefined;
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
  }, []);

  const showInvoicePage = (id: string) => {
    history.push(`${DASHBOARD}${INVOICES}/${id}`);
  };

  return (
      <div>
        {!loading &&
        <div>
          {(invoices && invoices.length !== 0) ?
              <TableContainer component={Paper}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.tableHeader}>
                      <TableCell className={classes.tableHeaderText}
                                 align="center">
                        Invoice No.
                      </TableCell>
                      <TableCell className={classes.tableHeaderText}
                                 align="left">
                        Patient Name
                      </TableCell>
                      <TableCell className={classes.tableHeaderText}
                                 align="left">
                        Amount
                      </TableCell>
                      <TableCell className={classes.tableHeaderText}
                                 align="left">
                        Issue Date
                      </TableCell>
                      <TableCell className={classes.tableHeaderText}
                                 align="left">
                        Due Date
                      </TableCell>
                      <TableCell className={classes.tableHeaderText}
                                 align="left">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoices.map((invoice: any) => (
                        <TableRow key={invoice.id}>
                          <TableCell
                              align="center">{invoice.invoiceNo}</TableCell>
                          <TableCell align="left">
                            {formatName(
                                invoice.firstName,
                                invoice.middleName,
                                invoice.lastName,
                            )}
                          </TableCell>
                          <TableCell
                              align="left">{`$${(Number(invoice.amount) /
                              100).toFixed(2)}`}</TableCell>

                          <TableCell align="left">
                            {fireStoreTimeStampToMUIDate(invoice.issueDate)}
                          </TableCell>
                          <TableCell align="left">
                            {fireStoreTimeStampToMUIDate(invoice.dueDate)}
                          </TableCell>
                          <TableCell align="left">
                            <Button
                                className={classes.tableBtn}
                                onClick={() => {
                                  showInvoicePage(invoice.id);
                                }}
                                size="small"
                                variant="contained"
                                color="primary"
                            >
                              View PDF
                            </Button>
                          </TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              :
              <div>
                <PatientsNotFound
                    imageFile={DoctorsPng}
                    imageText="No Invoices for this patient "
                />
              </div>
          }

        </div>
        }
      </div>
  );
};

export default ViewInvoices;

