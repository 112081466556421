import {firestore} from 'firebase';
import moment from 'moment';

export const dateStringToFirestoreTimeStamp = (
    date: string,
): firestore.Timestamp => {
    const dateObj = new Date(date);
    return firestore.Timestamp.fromDate(dateObj);
};

export const fireStoreTimeStampToMUIDate = (timeStamp: firestore.Timestamp) => {
    return moment(new Date(timeStamp.toDate().toDateString())).format(
        'MM/DD/YYYY',
    );
};


export const firestoreTimeStampToMUIDate2 = (timeStamp: firestore.Timestamp)=>{
    return moment(new Date(timeStamp.toDate().toDateString())).format(
       'yyyy-MM-DD',
    );
};

export const dateToMMDDYY = (date: Date) => {
    return moment(moment.utc(date)).format('MM/DD/YYYY');
};

export const getDayInterval = (date: any) => {
    const startDay = new Date(date);
    const endDay = new Date(date);

    startDay.setHours(0);
    startDay.setMinutes(0);
    startDay.setSeconds(0);

    endDay.setHours(23);
    endDay.setMinutes(59);
    endDay.setSeconds(59);

    return {
        startDay,
        endDay,
    };
};
