import React, {useCallback, useEffect} from 'react';
import {Grid, Container, Button, Typography,} from "@material-ui/core";
import {Field, FormikErrors, FormikTouched, FormikValues, Formik} from "formik";
import {PatientStepFormValues} from "../PatientForm";
import FirebaseUtil from "../../config/firebase";
import {useSnackbar} from "notistack";
import CustomInput from "../../components/CustomInput";
import {useParams} from "react-router-dom";
import {createStyles, makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
    createStyles({
        Typography: {
            margin: "20px"
        },
    }),
);

interface Props {
    values: FormikValues;
    errors: FormikErrors<PatientStepFormValues>;
    touched: FormikTouched<PatientStepFormValues>;
    setFieldValue: any;
    setLoading: any;
    patientId: string
}

interface Values {
    firstName: string;
    lastName: string;
    middleName: string;
    amount: string;
    reasonForVisit: string;
    description: string;
    dateOfVisit: string;
}

const GenerateInvoices: React.FC<Props> = ({setLoading}) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const id: any = useParams();

    useEffect(() => {
        (async () => {
                await fetchInvoice();
        })();
    }, []);

    const fetchInvoice = useCallback(async () => {
        const invoiceRef = await FirebaseUtil.firestore
            .collection('invoices')
            .where("patientId", "==", id)
            .get();

        return invoiceRef.docs.map(doc => doc.data());
    }, []);

    const handleFormSubmit = async (values: Values) => {
        await FirebaseUtil.firestore
            .collection('invoices')
            .add({...values, patientId: id.id})
        enqueueSnackbar(
            `invoices added successfully`,
            {
                variant: 'success',
                autoHideDuration: 3000,
                preventDuplicate: true,
                anchorOrigin: {horizontal: 'right', vertical: 'top'},
            },
        );
        setLoading(false);
    };

    return (
        <div>
            <Typography variant="h6" component="h3" className={classes.Typography}>
                Adding invoices
            </Typography>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item lg={12} md={6} xs={12}>
                        <Formik
                            enableReinitialize
                            initialValues={
                                {
                                    firstName: '',
                                    lastName: '',
                                    middleName: '',
                                    amount: '',
                                    reasonForVisit: '',
                                    description: '',
                                    dateOfVisit: '',
                                }
                            }
                            onSubmit={handleFormSubmit}
                        >
                            {({isSubmitting, handleSubmit, touched, errors}) => (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12} sm={6}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Field
                                                        variant="outlined"
                                                        style={{width: "110%"}}
                                                        s name="firstName"
                                                        as={CustomInput}
                                                        label="First Name *"
                                                        error={touched.firstName && errors.firstName && true}
                                                        helperText={touched.firstName && errors.firstName}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Field
                                                        variant="outlined"
                                                        style={{width: "110%"}}
                                                        name="lastName"
                                                        as={CustomInput}
                                                        label="Last name *"
                                                        error={touched.lastName && errors.lastName && true}
                                                        helperText={touched.lastName && errors.lastName}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Field
                                                        style={{width: "110%"}}
                                                        name="reasonForVisit"
                                                        as={CustomInput}
                                                        label="Reason for visit *"
                                                        variant="outlined"
                                                        error={touched.reasonForVisit && errors.reasonForVisit && true}
                                                        helperText={touched.reasonForVisit && errors.reasonForVisit}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Field
                                                        style={{width: "110%"}}
                                                        name="description"
                                                        as={CustomInput}
                                                        label="Description *"
                                                        variant="outlined"
                                                        error={touched.description && errors.description && true}
                                                        helperText={touched.description && errors.description}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Field
                                                        style={{width: "110%"}}
                                                        name="middleName"
                                                        as={CustomInput}
                                                        label="Middle Name *"
                                                        variant="outlined"
                                                        error={touched.middleName && errors.middleName && true}
                                                        helperText={touched.middleName && errors.middleName}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Field
                                                        style={{width: "110%"}}
                                                        name="amount"
                                                        as={CustomInput}
                                                        label="Amount *"
                                                        variant="outlined"
                                                        error={touched.amount && errors.amount && true}
                                                        helperText={touched.amount && errors.amount}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Field
                                                        style={{width: "110%"}}
                                                        name="dateOfVisit"
                                                        as={CustomInput}
                                                        variant="outlined"
                                                        label="Date Of Visit*"
                                                        type="date"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <div style={{width: "100px", height: "200px"}}>
                                        <Button
                                            style={{marginLeft: "350px", marginTop: "30px"}}
                                            disabled={isSubmitting}
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                        >
                                            Add invoice
                                        </Button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
export default GenerateInvoices;

