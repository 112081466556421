import React from 'react';

import ReactPDF, { Text, View, StyleSheet } from '@react-pdf/renderer';

export interface Item {
  service: string;
  patient?: string;
  date:any;
  reason:string;
  price: number;
}

export interface Props {
  items: Item[];
  style?: ReactPDF.Styles;
}

const borderBottomColor = '#b95244';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: borderBottomColor,
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 'auto',
    paddingTop: 10,
    paddingBottom: 10,
    fontStyle: 'bold',
    maxWidth: '100%',
  },
  halfWidth: {
    width: '50%',
  },
  service: {
    paddingLeft: 3,
  },
  price: {
    textAlign: 'right',
    paddingRight: '55%',
  },
});

const InvoiceTableRows: React.FC<Props> = ({ items }) => {
  return (
    <>
      {items.map((item, index) => (
        <View style={styles.row} key={index}>
          <View style={{ ...styles.service, ...styles.halfWidth }}>
            <Text>Service: {item.service}</Text>
            <Text>Patient Name: {item.patient}</Text>
            <Text>Date: {item.date}</Text>
            <Text style={{maxWidth:"80%"}}>Reason For Visit: {item.reason}</Text>
          </View>
          <View style={{width:"1px",backgroundColor:'#AE3431',height:'100%'}}/>
          <Text style={{ ...styles.halfWidth, ...styles.price }}>
            {`$${Number(item.price).toFixed(2)}`}
          </Text>
        </View>
      ))}
    </>
  );
};

export default InvoiceTableRows;
