import * as yup from 'yup';

const schema = yup.object({
  firstName: yup
    .string()
    .min(3, 'Please enter a valid name')
    .required('First Name is a Required Field'),
  middleName: yup.string(),
  lastName: yup
    .string()
    .min(3, 'Please enter a valid name')
    .required('Last Name is a Required Field'),
  birthDate: yup
    .string()
    .test('is less that today', 'Please Enter a Valid Date', (v: any) => {
      return new Date(v).getTime() < new Date().getTime();
    })
    .required('Date Of Birth is a Required Field'),
  gender: yup.string().required('Gender is a Required Field'),
  SSN: yup.string(),
});

export default schema;
