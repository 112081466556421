import { useState, useEffect } from 'react';
import FirebaseUtil from '../config/firebase';
import { Client } from '../models';
import firebase from 'firebase';

interface AuthState {
  user: Client | undefined;
  authStateLoading: boolean;
  role:string;
  isAuthenticated:boolean;
}

const fetchUserData = async (uid: string) => {
  const userRef = await FirebaseUtil.firestore
      .collection('users')
      .doc(uid)
      .get();


  const { name, email } = userRef.data() as any;

  return new Client(userRef.id, name, email);
};

const useAuth = (): AuthState => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState("");
  const [user, setUser] = useState<Client | undefined>(undefined);
  
  useEffect(() => {
    const unsubscribe = FirebaseUtil.auth.onAuthStateChanged(currentUser => {
      if (currentUser) {
        const { uid, emailVerified } = currentUser;
        setIsAuthenticated(true)
        if (!emailVerified) {
          setLoading(false);
          return;};
        firebase.auth().currentUser?.getIdTokenResult()
            .then(async(idTokenResult) => {
              let userRole: 'admin';
              if (idTokenResult.claims["admin"]) {
                userRole = "admin";
                setRole(userRole);
                const fetchedUser = await fetchUserData(uid);
                setUser({ ...fetchedUser });
                setLoading(false);
              }
              else{
                setRole("notAdmin");
                setUser(undefined);
                setLoading(false);
                setIsAuthenticated(false);
              }
            })
            .catch((error) => {
              setLoading(false);

            });
      } else {

        setUser(undefined);
        setLoading(false);
        setIsAuthenticated(false)
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return { user, authStateLoading: loading ,role,isAuthenticated};
};
export default useAuth;