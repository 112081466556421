import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';

import ComapnyLogo from './ComapnyLogo';
import InvoiceSummary, {Props as InvoiceSummaryProps} from './InvoiceSummary';
import InvoiceTableHead from './InvoiceTableHead';
import PDFAddressData, {Props as AddressDataProps} from './PDFAddressData';
import InvoiceTableRows, {
  Props as InvoiceTableRowsProps,
} from './InvoiceTableRows';
import InvoiceTableFooter from './InvoiceTableFooter';

export type Props = {
  userAddressData: AddressDataProps;
  invoiceSummary: InvoiceSummaryProps;
  invoiceTableData: InvoiceTableRowsProps;
  total: number;
};

const styles = StyleSheet.create({
  pdfViewer: {
    width: 940,
    height: 800,
  },
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  mainSection: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
  },
  twoSides: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  sideWidth: {
    minWidth: 120,
  },
  side: {
    flexDirection: 'row',
    marginBottom: 20,
    width: '100%',
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    border: `1px solid #b95244`,
  },
  thankMsg: {
    textAlign: 'center',
  },
});

const Invoice: React.FC<Props> = ({
  userAddressData,
  invoiceSummary,
  invoiceTableData,
  total,
}) => {
  return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.side}>
            <ComapnyLogo
                style={styles.sideWidth}
                img={require('../../Images/logo1.png')}
            />
            <PDFAddressData
                name="Kenosha Urgent Care"
                address1={'6430 Green Bay Rd'}
                address2={'Kenosha, WI 53142'}
                phone={'262-925-0535'}
                website="kenoshaurgicare.com"
            />
          </View>
          <View style={styles.twoSides}>
            <InvoiceSummary {...invoiceSummary} />
            <View style={{width: '90px'}}/>
            <View style={{marginTop: '35px'}}>
              <View>
                <Text>Account holder name: {userAddressData.name}</Text>
                <Text>Address: {userAddressData.address1}</Text>
                <Text>{userAddressData.address2
                    ? userAddressData.address2
                    : null}</Text>
                <Text>Email: {userAddressData.email}</Text>
              </View>
            </View>
          </View>
          <View style={styles.tableContainer}>
            <InvoiceTableHead/>
            <InvoiceTableRows {...invoiceTableData} />
            <InvoiceTableFooter total={total}/>
          </View>
          <Text style={styles.thankMsg}>THANK YOU FOR YOUR BUSINESS</Text>
        </Page>
      </Document>
  );
};

export default Invoice;
