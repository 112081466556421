import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
  Button,
  InputAdornment,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import FirebaseUtil from '../../config/firebase';
import {formatName} from '../../utilities/formatName';
import {DASHBOARD, INVOICES} from '../UserDashboard/constants';
import SortIcon from '@material-ui/icons/Sort';
import {fireStoreTimeStampToMUIDate} from '../../utilities/firestoreDates';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      btnWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
      },
      field: {
        width: '600px',
        marginRight: '20px',
        color: '#A01414',
      },
      tableHeader: {
        backgroundColor: theme.palette.primary.main,
      },
      tableHeaderText: {
        color: theme.palette.primary.contrastText,
      },

      table: {
        minWidth: '666px',
      },
      tableBtn: {
        width: '100px',
      },
      paidStatus: {
        color: 'green',
      },
      unPaidStatus: {
        color: theme.palette.primary.main,
      },
      status: {
        fontWeight: 'bold',
      },
    }),
);

const Invoices: React.FC = () => {
  const [invoices, setInvoices] = useState<any[]>([]);
  const [id, setId] = useState('');
  const classes = useStyles();
  const history = useHistory();
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [page, setPage] = React.useState(0);
  const [filter, setFilter] = useState('paid');
  const [sortByIssue, setSortByIssue] = useState<boolean>(true);
  const [dir, setDir] = useState<string>('desc');

  const fetchInvoices = async () => {
    const invoicesRef = sortByIssue ?
        await FirebaseUtil.firestore.collection('invoices')
            .orderBy('issueDate', 'desc')
            .limit(10)
            .get()
        :
        await FirebaseUtil.firestore.collection('invoices')
            .where('status', '==', filter)
            .orderBy('issueDate', 'desc')
            .limit(10)
            .get();
    if (invoicesRef.size > 0) {
      let lastdoc = null;
      let listOfInvoices = invoicesRef.docs.map((doc: any) => {
        lastdoc = doc;
        if (doc.id === '--stats--') return undefined;
        return {
          id: doc.id,
          ...doc.data(),
        };
      }).filter((e: any) => !!e);
      setLastDoc(lastdoc);
      setHasMore(true);
      setInvoices(listOfInvoices);
    }

  };

  const fetchById = useCallback(async () => {
    const invoicesRef = await FirebaseUtil.firestore.collection('invoices')
        .where('invoiceNo', '==', Number(id))
        .get();

    return invoicesRef.docs.map(doc => {
      if (doc.id === '--stats--') return undefined;
      return {
        id: doc.id,
        ...doc.data(),
      };
    }).filter(e => !!e);
  }, [id]);

  const showInvoicePage = (id: string) => {
    history.push(`${DASHBOARD}${INVOICES}/${id}`);
  };

  useEffect(() => {
    (async () => {
      if (!id) await fetchInvoices();
      else {
        const filteredInvoices = await fetchById();
        setInvoices(filteredInvoices);
      }
    })();
  }, [id, fetchById]);

  useEffect(() => {

    (async () => {
      await fetchInvoices();
    })();
  }, [filter, dir, sortByIssue]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setTimeout(() => setId(e.target.value), 2000);
  };
  const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    if (newPage > page && hasMore) {
      getMoreData();
    }

  };
  const getMoreData = async () => {
    if (hasMore) {

      const invoicesRef = sortByIssue ?
          await FirebaseUtil.firestore.collection('invoices')
              .orderBy('issueDate', 'desc')
              .limit(10)
              .startAfter(lastDoc)
              .get()
          :
          await FirebaseUtil.firestore.collection('invoices')
              .where('status', '==', filter)
              .orderBy('issueDate', 'desc')
              .limit(10)
              .startAfter(lastDoc)
              .get();

      if (invoicesRef.size > 0) {
        let lastdoc = null;
        let listOfInvoices = invoicesRef.docs.map(doc => {
          lastdoc = doc;
          if (doc.id === '--stats--') return undefined;
          return {
            id: doc.id,
            ...doc.data(),
          };
        }).filter(e => !!e);
        setLastDoc(lastdoc);
        setHasMore(true);
        setInvoices((invs: any) => [...invs, ...listOfInvoices]);
      } else {
        setHasMore(false);
      }
    }
  };
  const handleSortStatus = () => {
    setFilter(filter == 'paid' ? 'unpaid' : 'paid');
    setSortByIssue(false);
    setDir(dir == 'desc' ? 'asc' : 'desc');
  };
  const handleSortIssueDate = () => {
    setSortByIssue(true);
    setDir('desc');
  };
  return (
      <div>
        <div className={classes.btnWrapper}>
          <TextField
              className={classes.field}
              InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon/>
                      </SvgIcon>
                    </InputAdornment>
                ),
              }}
              variant="outlined"
              placeholder="Search By Invoices No."
              onChange={handleChange}
          />
          <Button startIcon={<SortIcon/>} onClick={handleSortIssueDate}>
            Sort by IssueDate
          </Button>
          <Button startIcon={<SortIcon/>} onClick={handleSortStatus}>
            Filter by {dir == 'desc' ? 'Unpaid' : 'Paid'}
          </Button>
        </div>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell className={classes.tableHeaderText} align="center">
                  Invoice No.
                </TableCell>
                <TableCell className={classes.tableHeaderText} align="left">
                  Patient Name
                </TableCell>
                <TableCell className={classes.tableHeaderText} align="left">
                  Amount
                </TableCell>
                <TableCell className={classes.tableHeaderText} align="left">
                  Issue Date
                </TableCell>
                <TableCell className={classes.tableHeaderText} align="left">
                  Due Date
                </TableCell>
                <TableCell className={classes.tableHeaderText} align="left">
                  Status
                </TableCell>
                <TableCell className={classes.tableHeaderText} align="left">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.length > 0 && invoices.slice(page * 10, page * 10 + 10)
                  .map((invoice: any) => (
                      <TableRow key={invoice.id}>
                        <TableCell
                            align="center">{invoice.invoiceNo}</TableCell>
                        <TableCell align="left">
                          {formatName(
                              invoice.firstName,
                              invoice.middleName,
                              invoice.lastName,
                          )}
                        </TableCell>
                        <TableCell align="left">{`$${(Number(invoice.amount) /
                            100).toFixed(2)}`}</TableCell>
                        <TableCell align="left">
                          {fireStoreTimeStampToMUIDate(invoice.issueDate)}
                        </TableCell>
                        <TableCell align="left">
                          {fireStoreTimeStampToMUIDate(invoice.dueDate)}
                        </TableCell>
                        <TableCell
                            className={clsx(
                                classes.status,
                                invoice.status === 'paid'
                                    ? classes.paidStatus
                                    : classes.unPaidStatus,
                            )}
                            align="left"
                        >
                          {invoice.status === 'paid' ? 'Paid' : 'Unpaid'}
                        </TableCell>
                        <TableCell align="left">
                          <Button
                              className={classes.tableBtn}
                              onClick={() => {
                                showInvoicePage(invoice.id);
                              }}
                              size="small"
                              variant="contained"
                              color="primary"
                          >
                            View PDF
                          </Button>
                        </TableCell>
                      </TableRow>
                  ))}
            </TableBody>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <TableFooter>
              <TableRow>
                <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={20}
                    count={hasMore ? -1 : invoices.length}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={10}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
  );
};

export default Invoices;
