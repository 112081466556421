import React, { useEffect } from 'react';
import FirebaseUtil from '../config/firebase';
import snackbarOpts from '../utilities/comonSnackBarOpt';
import { useSnackbar } from 'notistack';
import { Redirect } from 'react-router-dom';

const NoAuth = () => {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    FirebaseUtil.signOut();
    enqueueSnackbar('Unauthorized', {
      variant: 'error',
      ...snackbarOpts,
    });
  }, []);
  return <Redirect to="/login" />;
};

export default NoAuth;
