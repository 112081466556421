export const formatName = (first: string, middle: string, last: string) => {

  let formatedName = first + ' ';
  if (middle) {
    if (middle.length === 1) formatedName += middle.toUpperCase() + '. ';
    else formatedName += middle + ' ';
  }
  formatedName += last;
  return formatedName;
};
