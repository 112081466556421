import React, { useContext } from 'react';
import {
  PATIENT,
  ONLINE,
  APPOITMENT,
  PROFILE,
  INVOICES,
  ADMIN,
  DASHBOARD,
  CREATINVOICES,
  VIEWINVOICES, APPOINTMENT,

} from './constants';
import {
  ApplicationBar,
  DesktopDrawer,
  ClientDashboardSpinner,
} from '../../components';

import { Redirect } from 'react-router-dom';
import { UserContext } from '../../context/UserProvider';

import { PrivateRoute } from '../../components/Routes';
import { Switch, useRouteMatch } from 'react-router-dom';

import {
  Admin,
  Invoices,
  Patients,
  OnlineHours,
  PatientProfile,
  ViewInvoices,
  GenerateInvoices,
  Appointments,
} from '../../containers';
import PageProvider from '../../context/currentDashPage';
import InvoicePage from '../InvoicePage';
import Appointment from "../Appointment/appointment";

const UserDashboard: React.FC = () => {
  const context = useContext(UserContext);
  const { path } = useRouteMatch();
  return (
    <>
      
        <>
          <PageProvider>
            <DesktopDrawer />
            <ApplicationBar />
            <div className="dashboard-component">
              <Switch>

              <PrivateRoute
                  exact
                  path={`${DASHBOARD}`}
                  component={Appointments}
                />
                <PrivateRoute
                  exact
                  path={`${DASHBOARD}${ADMIN}`}
                  component={Admin}
                />
                <PrivateRoute
                  exact
                  path={`${DASHBOARD}${ONLINE}`}
                  component={OnlineHours}
                />
                <PrivateRoute
                  exact
                  path={`${DASHBOARD}${APPOITMENT}`}
                  component={Appointments}
                />
                <PrivateRoute
                    exact
                    path={`${DASHBOARD}${APPOINTMENT}/:id`}
                    component={Appointment}
                />
                <PrivateRoute
                  exact
                  path={`${DASHBOARD}${INVOICES}`}
                  component={Invoices}
                />
                <PrivateRoute
                  exact
                  path={`${DASHBOARD}${PATIENT}`}
                  component={Patients}
                />
                <PrivateRoute
                  exact
                  path={`${path}${CREATINVOICES}/:id`}
                  component={GenerateInvoices}
                />
                <PrivateRoute
                  exact
                  path={`${DASHBOARD}${INVOICES}/:id`}
                  component={InvoicePage}
                />
                <PrivateRoute
                  exact
                  path={`${path}${VIEWINVOICES}/:id`}
                  component={ViewInvoices}
                />
                <PrivateRoute
                  exact
                  path={`${DASHBOARD}${PROFILE}/:id`}
                  component={PatientProfile}
                />

              </Switch>
            </div>
          </PageProvider>
        </>
      
    </>
  );
};

export default UserDashboard;
