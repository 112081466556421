import React, { createContext, useState } from 'react';

interface PageContextType {
  page: string;
  [index: string]: any;
}

export const PageContext = createContext<PageContextType>({ page: '' });

const PageProvider: React.FC = ({ children }) => {
  const [page, setPage] = useState('');
  return (
    <PageContext.Provider value={{ page, setPage }}>
      {children}
    </PageContext.Provider>
  );
};

export default PageProvider;
