export const format24To12 = (time: string) => {
    const [hours, mins] = time.split(':');
    if (Number(hours) < 12) return hours + ':' + mins + ' AM';
    if (Number(hours) === 12) return hours + ':' + mins + ' PM';
    else {
        let numHours = (Number(hours) - 12).toString();
        if (numHours.length === 1) numHours = '0' + numHours;
        return numHours + ':' + mins + ' PM';
    }
};
