import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#AE3431',
      dark: '#333333',
    },
    secondary: {
      main: '#FAFBFC',
      dark: '#666666',
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        '& label.Mui-focused': {
          color: '#333',
          fontFamily: 'Montserrat, sans-serif',
          fontWeight: '300',
          fontSize: '14px',
        },

        '& label.MuiInputLabel-shrink': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '15px',
          color: '#333',
          transform: 'translate(12px, 7px) scale(0.75)',
        },
      },
    },
    MuiButton: {
      sizeLarge: {
        width: '358px',
        height: '61px',
        fontSize: '14px',
        fontFamily: "'Montserrat',sans-serif",
        color: '#fff',
        borderRadius: '4px',
      },
      containedSizeSmall: {
        width: '185px',
        height: '48px',
        fontSize: '14px',
        fontFamily: "'Montserrat',sans-serif",
        color: '#fff',
        borderRadius: '4px',
      },
      outlined: {
        width: '250px',
        height: '59px',
        fontSize: '14px',
        fontFamily: "'Montserrat',sans-serif",
        borderRadius: '4px',
      },
      contained: {
        width: '250px',
        height: '59px',
        fontSize: '14px',
        fontFamily: "'Montserrat',sans-serif",
        color: '#fff',
        borderRadius: '4px',
      },
    },
  },
  typography: {
    h1: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'bold',
      fontSize: '60px',
    },
    h2: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'normal',
      fontSize: '38px',
    },
    h3: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'normal',
      fontSize: '35px',
    },
    h4: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'normal',
      fontSize: '28px',
    },
    h5: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'bold',
      fontSize: '24px',
    },
    h6: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'bold',
      fontSize: '18px',
    },
    body1: {},
    body2: {},
  },
});

export default theme;
