import React, { useContext, useEffect, useState } from "react";
import {
  IconButton,
  InputAdornment,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useSnackbar } from "notistack";
import axios from "axios";
import config from "../../config";
import moment from "moment";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DashboardDialog, TableSpiner } from "../../components";
import PatientForm from "../PatientForm";
import FirebaseUtil from "../../config/firebase";
import { UserContext } from "../../context/UserProvider";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { patientConverter, PatinetType } from "../../models/patient";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import firebase from "firebase/app";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableHeader: {
      backgroundColor: theme.palette.primary.main,
    },
    tableHeaderText: {
      color: theme.palette.primary.contrastText,
    },
    btnWrapper: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: "2.75rem",
    },
    table: {
      minWidth: "666px",
    },
    search: {
      color: theme.palette.primary.contrastText,
      Width: "666px",
      backgroundColor: theme.palette.primary.main,
      marginBottom: "2.75rem",
    },
    highlightOffIcon: {
      color: theme.palette.primary.main,
    },
  })
);
const Patients: React.FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const [patients, setPatients] = useState<PatinetType[]>([]);
  const [searchValue, setSearchValue] = useState<any>("");
  const [loading, setLoading] = useState(true);
  const [selectedPatientId, setSelectedPatientId] = useState<
    string | undefined
  >();
  const [showDialog, setShowDialog] = useState(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [lastDoc, setLastDoc] = useState(null);
  const [page, setPage] = React.useState(0);

  const [patientId, setPatientId] = useState<string | undefined>();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [triggerDelete, setTrigger] = useState(false);
  const [Name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleDeletePatient = (id: string) => {
    setOpenDelete(true);
    setPatientId(id);
  };
  const handleSendEmail = async (id: string, userId: string) => {
    let name = "";
    let email = "";
    setPatientId(id);
    await FirebaseUtil.firestore
      .collection("users")
      .doc(userId)
      .get()
      .then((result) => {
        if (result?.exists) {
          name = result.data()?.name;
          email = result.data()?.email;
        }
      });
    EmailReview(id, email, name);

    //     EmailReview(id , email , firstName)
  };

  const deletePatient = async () => {
    const oldPatients = [...patients];
    const updatedPats = patients.filter(
      (patient: any) => patientId !== patient.id
    );

    try {
      await FirebaseUtil.firestore
        .collection("patients")
        .doc(patientId)
        .set({ isDeleted: true }, { merge: true });
      await FirebaseUtil.firestore
        .collection("users")
        .doc(user?.id)
        .set(
          {
            patientIds: firebase.firestore.FieldValue.arrayRemove(patientId),
          },
          { merge: true }
        );
      setPatients(updatedPats);
      setTrigger(true);
      enqueueSnackbar("Patient deleted successfully", {
        variant: "success",
        autoHideDuration: 3000,
        preventDuplicate: true,

        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    } catch (e) {
      setPatients(oldPatients);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        autoHideDuration: 3000,
        preventDuplicate: true,

        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
    handleCloseDelete();
  };

  useEffect(() => {
    fetchPatients();
  }, [loading]);

  function getDate(birthDate: any) {
    const seconds = birthDate.seconds || birthDate._seconds || birthDate;
    let epoch = new Date(1970, 0, 1);
    epoch.setSeconds(seconds);
    let updatedDate = new Date(epoch);
    return `${(updatedDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${updatedDate
      .getDate()
      .toString()
      .padStart(2, "0")}/${updatedDate.getFullYear()}`;
  }

  const fetchPatients = async () => {
    await FirebaseUtil.firestore
      .collection("patients")
      .withConverter(patientConverter)
      .where("isDeleted", "==", false)
      .orderBy("lastName")
      .limit(10)
      .get()
      .then((result) => {
        if (!result.empty) {
          let lastdoc = null;
          const patientsList = result.docs.map((doc) => {
            lastdoc = doc;
            return { id: doc.id, ...doc.data() };
          });
          setLastDoc(lastdoc);
          setHasMore(true);
          setPatients(patientsList);
          setLoading(false);
        }
      });
  };
  const getMorePatients = async () => {
     if (hasMore) {
       await FirebaseUtil.firestore
         .collection("patients")
         .withConverter(patientConverter)
         .where("isDeleted", "==", false)
         .orderBy("lastName")
         .limit(10)
         .startAfter(lastDoc)
         .get()
         .then((result) => {
           if (!result.empty) {
             let lastdoc = null;
             const patientsList = result.docs.map((doc) => {
               lastdoc = doc;
               return { id: doc.id, ...doc.data() };
             });
             setLastDoc(lastdoc);
             setHasMore(true);
             setPatients((patients) => [...patients, ...patientsList]);
           } else {
             setHasMore(false);
           }
         });
     }
   };
  const EmailReview = async (id: string, email: string, name: string) => {
   
    try {
      await axios
        .post(`${config.baseUrl}/sendEmailGoogleReviews`, {
          email:email,
          name: name,
        })
        .then(async (s) => {
        if (s.status===200 || s.status===201){
          await FirebaseUtil.firestore
          .collection("patients")
          .doc(id)
          .set({ googleReview: true, ReviewDate: new Date() }, { merge: true });
          await FirebaseUtil.firestore
          .collection("patients")
          .withConverter(patientConverter)
          .where("isDeleted", "==", false)
          .orderBy("lastName")
          .get()
          .then((result) => {
            if (!result.empty) {
              let lastdoc = null;
              const patientsList = result.docs.map((doc) => {
                lastdoc = doc;
                return { id: doc.id, ...doc.data() };
              });
              
          
              setPatients(patientsList);
              setLoading(false);
            }
          });
        }
     })
    } catch (e) {
      console.log(e, "ee");
    }
   
  };
useEffect(()=>{
   console.log(patients)
},[patients])

  const searchPatient = async () => {
    let patients: any = [];
    await FirebaseUtil.firestore
      .collection("patients")
      .withConverter(patientConverter)
      .where("isDeleted", "==", false)
      .orderBy("firstName")
      .startAt(searchValue)
      .endAt(searchValue + "\uf8ff")
      .get()
      .then((result) => {
        if (!result.empty) {
          patients = result.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          });
        }
      });
    await FirebaseUtil.firestore
      .collection("patients")
      .withConverter(patientConverter)
      .where("isDeleted", "==", false)
      .orderBy("lastName")
      .startAt(searchValue)
      .endAt(searchValue + "\uf8ff")
      .get()
      .then((result) => {
        if (!result.empty) {
          const lastNamePatients = result.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          });
          patients = [...patients, ...lastNamePatients];
        }
      });

    setPatients(patients);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    if (e.target.value === "") {
      fetchPatients();
    }
    const value = e.target.value.trim();
    const capatalize = value.charAt(0).toUpperCase() + value.slice(1) 
    setSearchValue(capatalize);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    if (newPage > page && hasMore) {
      getMorePatients();
    }
  };
  return (
    <div>
      {showDialog && (
        <DashboardDialog
          showDialog={true}
          handleClose={() => setShowDialog(false)}
        >
          <PatientForm
            title={selectedPatientId ? "Update Patient" : "Add New Patient"}
            patientId={selectedPatientId}
            setLoading={setLoading}
            handleClose={() => setShowDialog(false)}
          />
        </DashboardDialog>
      )}

      <div className={classes.btnWrapper}>
        <TextField
          style={{ width: "500px", marginRight: "20px", color: "#A01414" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          placeholder="Search using patient's first name or last name."
          onChange={handleChange}
          value={searchValue}
        />
        <Button
          onClick={searchPatient}
          variant={"contained"}
          size={"small"}
          style={{ marginRight: "20px", backgroundColor: "#A01414" }}
        >
          Search
        </Button>
        <Button
          variant="contained"
          style={{ backgroundColor: "#A01414" }}
          size="small"
          disableElevation
          onClick={() => {
            setSelectedPatientId(undefined);
            setShowDialog(true);
          }}
        >
          Add Patient
        </Button>
      </div>

      {!loading ? (
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell className={classes.tableHeaderText} align="center">
                  No.
                </TableCell>
                <TableCell className={classes.tableHeaderText} align="left">
                  Patient Name
                </TableCell>
                <TableCell className={classes.tableHeaderText} align="left">
                  Date Of Birth
                </TableCell>
                <TableCell className={classes.tableHeaderText} align="left">
                  Gender
                </TableCell>
                <TableCell className={classes.tableHeaderText} align="left">
                  Invoices
                </TableCell>
                <TableCell className={classes.tableHeaderText} align="left">
                  Actions
                </TableCell>
                <TableCell className={classes.tableHeaderText} align="left">
                  Google review
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {patients.length > 0 &&
                patients
                  .slice(page * 10, page * 10 + 10)
                  .map((p: any, index: number) => (
                    <TableRow key={p.id}>
                      <TableCell align="center">
                        {p.id == patientId && triggerDelete ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <HighlightOffIcon
                              className={classes.highlightOffIcon}
                            />{" "}
                            {index + 1}
                          </div>
                        ) : (
                          <span>{index + 1}</span>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {p.firstName + " " + p.middleName + " " + p.lastName}
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        {p.dateOfBirth ? getDate(p.dateOfBirth) : ""}
                      </TableCell>
                      <TableCell align="left">
                        {p.gender == "female" ? "Female" : "Male"}
                      </TableCell>
                      <TableCell align="left">
                        <IconButton
                          component={RouterLink}
                          to={`ViewInvoices/${p.id}`}
                        >
                          <ReceiptIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell align="left">
                        <IconButton
                          component="span"
                          onClick={() => {
                            setSelectedPatientId(p.id);
                            setShowDialog(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          component={RouterLink}
                          to={`PatientProfile/${p.id}`}
                        >
                          <AccountBoxIcon />
                        </IconButton>
                        <IconButton
                          component="span"
                          onClick={() => handleDeletePatient(p.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        {p?.googleReview ? (
                         <Typography variant="body2" style={{display:'flex', justifyContent:'center'}}>
                           Sent
                         </Typography>
                         
                        ) : (
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "#A01414" }}
                            size="small"
                            disableElevation
                            onClick={() => handleSendEmail(p.id, p?.userId)}
                          >
                            Send
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={20}
                  count={hasMore ? -1 : patients.length}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={10}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <TableSpiner />
      )}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this patient?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deletePatient} color="primary">
            yes
          </Button>
          <Button onClick={handleCloseDelete} color="primary" autoFocus>
            Nod
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Patients;
