import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
import { format24To12 } from '../utilities/format24To12';
import axios from 'axios';
import {  TimePicker } from '@material-ui/pickers';
import config from '../config';
import {
  Grid,
  Button,
  makeStyles,
  createStyles,
  Typography,
  DialogActions,
  DialogContent,
  Dialog,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import FirebaseUtil from '../config/firebase';
import {ClickableChip, CustomButton, TableSpiner} from '../components';
import comonSnackbarOpts from '../utilities/comonSnackBarOpt';
import PatientsNotFound from "../components/PatientsNotFound";
import DoctorsPng from "../Images/doctors.png";

const useStyles = makeStyles(() =>
  createStyles({

    blocksWrapper: {
      marginTop: '2rem',
    },
    button: {
      marginLeft: '30px',
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    field: {
      whiteSpace: 'nowrap',
      color: '#a31b35',
      height: '50%',
      margin: '20px',
      width: '40%',
      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  }),
);

const OnlineHours: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [fromTime, setFromTime] = useState<any>(
      moment(new Date().setMinutes(0))
  );
  const [toTime, setToTime] = useState<any>(moment(new Date().setMinutes(0)));
  const [maxPatients, setMaxPatients] = useState<any>('');
  const [blocks, setBlocks] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(moment(new Date().setMinutes(0)));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
   const [openDelete, setOpenDelete] = React.useState(false);
  const [blockId,setBlockId]=useState('');

  const getDayInterval = (date: any) => {
    const startDay = new Date(date);
    const endDay = new Date(date);

    startDay.setHours(0);
    startDay.setMinutes(0);
    startDay.setSeconds(0);

    endDay.setHours(23);
    endDay.setMinutes(59);
    endDay.setSeconds(59);

    return {
      startDay,
      endDay,
    };
  };

  const fetchHours = useCallback(async () => {
    const { startDay, endDay } = getDayInterval(selectedDate);
    const ref = await FirebaseUtil.firestore
      .collection('blocks')
      .where('fromTime', '>=', startDay)
      .where('fromTime', '<=', endDay)
      .where('isDeleted', '==', false)
      .get();

    const records: any[] | PromiseLike<any[]> = [];
    ref.docs.forEach(doc => {
      if (!doc.data().isDeleted) {
        records.push(doc.data());
      }
    });

    return records;
  }, [selectedDate]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const fetchedHours = await fetchHours();
      setBlocks(fetchedHours);
      setLoading(false);
    })();
  }, [fetchHours, selectedDate]);

  const handleAdd = async () => {
    setIsSubmitting(true);
    if (!maxPatients || isNaN(Number(maxPatients)) || Number(maxPatients) < 1) {
      setIsSubmitting(false);
      enqueueSnackbar('Please enter a valid number of patients', {
        variant: 'error',
        ...comonSnackbarOpts,
      });
      return;
    }
    if (new Date(toTime) <= new Date(fromTime) || !fromTime || !toTime) {
      setIsSubmitting(false);
      enqueueSnackbar('Wrong time ', {
        variant: 'error',
        ...comonSnackbarOpts,
      });
      return;
    }
    let blockRef = FirebaseUtil.firestore.collection('blocks').doc();
    let obj = {
      fromTime: new Date(fromTime.toISOString()),
      toTime: new Date(toTime.toISOString()),
      maxPatients: maxPatients,
      blockId: blockRef.id,
      isDeleted: false,
    };
    await blockRef.set(obj).then(async () => {
      setIsSubmitting(false);
      let obj = {
        fromTime: fromTime.toISOString(),
        toTime: toTime.toISOString(),
        maxPatients: maxPatients,
        blockId: blockRef.id,
        isDeleted: false,
      };

      setOpen(false);
      setBlocks((prevState: any) => [...prevState, obj]);
      enqueueSnackbar('hours added successfully', {
        variant: 'success',
        ...comonSnackbarOpts,
      });
    });
    setFromTime(selectedDate);
    setToTime(selectedDate);
    setMaxPatients('');
  };

  const handleToTime = (value: any) => {
    setToTime(value);
  };
  const handleFromTime = (value: any) => {
    setFromTime(value);
  };
  const handleMaxSpots = (e: any) => {
    setMaxPatients(e.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (date: any) => {
    let d = moment(new Date(date).setMinutes(0));
    setFromTime(d);
    setToTime(d);
    setSelectedDate(date);
  };
  const handleDelete = async () => {
    setIsSubmitting(true);
    const oldBlocks = [...blocks];
    setBlocks(blocks.filter((block: any) => block.blockId !== blockId));
    handleCloseDelete();
    enqueueSnackbar('block deleted successfully', {
      variant: 'success',
      autoHideDuration: 3000,
      preventDuplicate: true,
      anchorOrigin: { horizontal: 'right', vertical: 'top' },
    });
    const token = await FirebaseUtil.auth.currentUser?.getIdToken(false);
    try {
      await axios.post(
        `${config.baseUrl}/deleteBlock`,
        {
          blockId: blockId,
        },
        {
          headers: {
            authorization: token,
          },
        },
      );
    } catch (e) {
      enqueueSnackbar('something went wrong', {
        variant: 'error',
        autoHideDuration: 3000,
        preventDuplicate: true,
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
      });
      setBlocks(oldBlocks);
    }
    setIsSubmitting(false)
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleDeleteBlock = (id:string) => {
    setOpenDelete(true)
    setBlockId(id);
  }
  return (
    <div>
      <Dialog
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this block?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton
             disabled={isSubmitting}
             loading={isSubmitting}
             onClick={handleDelete}
              color="primary">
            yes
          </CustomButton>
          <Button
             disabled={isSubmitting}
              onClick={handleCloseDelete}
              color="primary"
              autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container direction="row">
        <Grid item xs={12} md={7}>
          <Grid item xs={12}>
            <div >
              <Typography variant="h6" component="h3">
                Select a Date
              </Typography>
              <KeyboardDatePicker
                  disablePast
                margin="normal"
                format="MM/DD/yyyy"
                value={selectedDate}
                onChange={handleChange}
                showTodayButton
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </div>
          </Grid>
          <Grid className={classes.blocksWrapper} item xs={12}>
            <Typography variant="h6" component="h3">
              Online Hours
            </Typography>

            {loading ? (
              <TableSpiner />
            ) : (
              <>
                {blocks.length !== 0 ? (
                  <>
                    {blocks &&
                      blocks.map((block: any) => {
                        return (
                          <ClickableChip
                            icon={<AccessAlarmsIcon />}
                            style={{margin:'5px 10px'}}
                            clicked={true}
                            onDelete={() => handleDeleteBlock(block.blockId)}
                            label={
                              typeof block.fromTime === 'string'
                                ? format24To12(
                                    moment(block.fromTime).format('HH:mm'),
                                  ) +
                                  ' - ' +
                                  format24To12(
                                    moment(block.toTime).format('HH:mm'),
                                  ) +
                                  ' for ' +
                                  block.maxPatients +
                                  ' Patients'
                                : format24To12(
                                    moment.unix(block.fromTime).format('HH:mm'),
                                  ) +
                                  ' - ' +
                                  format24To12(
                                    moment.unix(block.toTime).format('HH:mm'),
                                  ) +
                                  ' for  ' +
                                  block.maxPatients +
                                  ' Patients'
                            }
                          />
                        );
                      })}
                  </>
                ) : (
                  <div style={{margin:'0 auto'}}>
                    <PatientsNotFound
                        imageFile={DoctorsPng}
                        imageText="No Online Hours For This Day. "
                    />
                  </div>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs container justify="center">
          <Button
            color="primary"
            variant="contained"
            aria-label="add"
            onClick={handleClickOpen}
            startIcon={<AddIcon />}
          >
            Add Online Hours
          </Button>
        </Grid>
      </Grid>

      <Dialog open={open} aria-labelledby="Add Doctor">
        <DialogTitle id="Add Online Hour" style={{ color: '#a31b35' }}>
          Add Online Hour
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please add start and end times .
          </DialogContentText>
          <TimePicker
            className={classes.field}
            minutesStep={30}
            required
            value={fromTime}
            onChange={handleFromTime}
            label="Start Time"
            showTodayButton
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TimePicker
            className={classes.field}
            minutesStep={30}
            required
            value={toTime}
            onChange={handleToTime}
            label="End Time"
            showTodayButton
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            className={classes.field}
            required
            id="name"
            label=" Max Patient "
            type="number"
            fullWidth
            onChange={handleMaxSpots}
            value={maxPatients}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isSubmitting} color="primary">
            Cancel
          </Button>
          <CustomButton disabled={isSubmitting} loading={isSubmitting} onClick={handleAdd} color="primary">
            Add
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OnlineHours;
