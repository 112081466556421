import React from 'react';

import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderBottomColor = '#b95244';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: borderBottomColor,
    backgroundColor: borderBottomColor,
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
  },
  label: {
    color: '#fff',
    width: '50%',
  },
});

const InvoiceTableHead: React.FC = () => {
  return (
    <View>
      <View style={styles.container}>
        <Text style={styles.label}>Service</Text>
        <Text style={styles.label}>Price</Text>
      </View>
    </View>
  );
};

export default InvoiceTableHead;
