import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
} from 'react';

import {
    Grid,
    Typography,
    IconButton,
    Button,
    DialogContent,
    DialogContentText,
    DialogActions,
    Dialog,
    TablePagination,
    TableRow,
    TableFooter,
    TableBody,
    TableCell,
    TableHead,
    TableContainer, Table, Paper,
} from '@material-ui/core';
import PatientsNotFound from '../../components/PatientsNotFound';
import DoctorsPng from '../../Images/doctors.png';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import FirebaseUtil from '../../config/firebase';
import {format24To12} from '../../utilities/format24To12';
import {
    getDayInterval,
} from '../../utilities/firestoreDates';

import {useSnackbar} from 'notistack';
import {formatName} from "../../utilities/formatName";
import {extractTimeFromDate} from '../../utilities/extractTimeFromDate';
import {fireStoreTimeStampToMMDDYY} from '../../utilities/firebaseDates';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PersonIcon from '@material-ui/icons/Person';
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(() =>
    createStyles({
        datePickerWrapper: {
            width: '200px',
        },
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        Button: {
            fontSize: 10,
            margin: '1px',
        },
    }),
);
const useStyles2 = makeStyles((theme: Theme) =>
    createStyles({
        tableHeader: {
            backgroundColor: theme.palette.primary.main,
        },
        tableHeaderText: {
            color: theme.palette.primary.contrastText,
        },
        btnWrapper: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '2.75rem',
        },
        table: {
            minWidth: '666px',
        },
        smallScreenHeader: {
            fontWeight: 'bold',
            color: theme.palette.primary.main
        },
        smallScreenText: {
            borderBottom: `2px solid ${theme.palette.primary.main}`,
            paddingBottom: '5px'
        },
        smallScreenLastText: {
            borderBottom: `5px solid ${theme.palette.primary.main}`,
            marginBottom: '20px'
        }
    }),
);

interface Props {
  patientId: string;
  slotId: string;
  userId: string;
  insuranceId: string;
  className?: string;
}

const Appointments: React.FC<Props> = () => {
    const classes = useStyles();
    const classes2 = useStyles2();
    const history = useHistory()
    const [appointments, setAppointments] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const mounted = useRef(true);
    const [openDelete, setOpenDelete] = React.useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [selectedAppointment, setSelectedAppointment] = useState<any>();
    const [page, setPage] = React.useState(0);
    const [hasMore, setHasMore] = useState<boolean>(false);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
        if (newPage > page && hasMore) {
            //getMoreData();
        }

    };

    const fetchAppointments = useCallback(async () => {
        const {startDay, endDay} = getDayInterval(selectedDate);
        await FirebaseUtil.firestore
            .collection('appointments')
            .where('fromTime', '>=', startDay)
            .where('fromTime', '<=', endDay)
            .where('isDeleted', '==', false)
            .onSnapshot(querySnapshot => {
                const records = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    const fromTime = format24To12(
                        extractTimeFromDate(data.fromTime.toDate()),
                    );
                    const toTime = format24To12(
                        extractTimeFromDate(data.toTime.toDate()),
                    );
                    const day = fireStoreTimeStampToMMDDYY(data.fromTime);
                    return {
                        ...data,
                        fromTime,
                        toTime,
                        day,
                        id: doc.id
                    };
                });
                setAppointments(records);
            });
    }, [selectedDate, setAppointments]);

    useEffect(() => {
        (async () => {
            await fetchAppointments();
            setLoading(false);
        })();

    return () => {
      mounted.current = false;
    };
  }, [fetchAppointments, selectedDate]);

    const handleDelete = async () => {
        const invoiceRef = await FirebaseUtil.firestore.collection('invoices')
            .where("appointmentId", "==", selectedAppointment)
            .get();

        if (!invoiceRef.empty) {
            handleCloseDelete();
            enqueueSnackbar("Appointment has an invoice and can't be cancelled", {
                variant: 'error',
                autoHideDuration: 3000,
                preventDuplicate: true,
                anchorOrigin: {horizontal: 'right', vertical: 'top'},
            });
            return;
        }


        await FirebaseUtil.firestore.collection('appointments')
            .doc(selectedAppointment)
            .set({isDeleted: true}, {merge: true});
        handleCloseDelete();
        setSelectedAppointment(undefined);
        setAppointments(appointments.filter((app: any) => app.id !== selectedAppointment));
        enqueueSnackbar('Appointment deleted successfully', {
            variant: 'success',
            autoHideDuration: 3000,
            preventDuplicate: true,
            anchorOrigin: {horizontal: 'right', vertical: 'top'},
        });
    };

    const handleChange = (data: any) => {
        setSelectedDate(data);
        setSelectedAppointment(null);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };
    const handleDeleteAppointment = (id: string) => {
        setSelectedAppointment(id)
        setOpenDelete(true);
    };


    const goToAppointment = (id: string) => {
        history.push(`/dashboard/appointment/${id}`)
    }
    return (
        <Grid container spacing={3} justify="space-between">
            <>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div className={classes.datePickerWrapper}>
                                <Typography variant="h6" component="h3">
                                    Select a Date
                                </Typography>
                                <Grid container justify="space-around">
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        format="MM/DD/yyyy"
                                        value={selectedDate}
                                        onChange={handleChange}
                                        showTodayButton
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                {appointments.length !== 0 ? (
                        <Grid item xs={12} style={{marginBottom:'20px'}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" component="h3">
                                        Appointments
                                    </Typography>
                                </Grid>
                                <TableContainer component={Paper}>
                                    <Table className={classes2.table}>
                                        <TableHead>
                                            <TableRow className={classes2.tableHeader}>
                                                <TableCell className={classes2.tableHeaderText} align="center">
                                                    No.
                                                </TableCell>
                                                <TableCell className={classes2.tableHeaderText} align="left">
                                                    Patient Name
                                                </TableCell>
                                                <TableCell className={classes2.tableHeaderText} align="left">
                                                    Time
                                                </TableCell>
                                                <TableCell className={classes2.tableHeaderText} align="left">
                                                    Reason for visit
                                                </TableCell>
                                                <TableCell className={classes2.tableHeaderText} align="left">
                                                    Flu Shots
                                                </TableCell>
                                                <TableCell className={classes2.tableHeaderText} align="left">
                                                    Actions
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {appointments.length > 0 && appointments.slice(page * 10, page * 10 + 10)
                                                .map((ele: any, index: number) => (
                                                    <TableRow key={ele.id}>
                                                        <TableCell align="center">{index + 1}</TableCell>
                                                        <TableCell align="left">
                                                            {formatName(
                                                                ele.firstName,
                                                                ele.middleName,
                                                                ele.lastName,
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {ele.fromTime + ' - ' + ele.toTime}
                                                        </TableCell>
                                                        <TableCell align="left">{ele.reasonForVisit}</TableCell>
                                                        <TableCell
                                                            align="left">{ele.wantFluShot ? "Interested" : "Not Interested"}</TableCell>
                                                        <TableCell
                                                            align="left">
                                                            <IconButton
                                                                onClick={() => goToAppointment(ele.id)}>
                                                                <PersonIcon/>
                                                            </IconButton>
                                                            <IconButton onClick={() => handleDeleteAppointment(ele.id)}>
                                                                <DeleteForeverIcon/>
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>

                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[]}
                                                    colSpan={20}
                                                    count={hasMore ? -1 : appointments.length}
                                                    page={page}
                                                    onChangePage={handleChangePage}
                                                    rowsPerPage={10}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    ) :
                    (
                        <div>
                            <PatientsNotFound
                                imageFile={DoctorsPng}
                                imageText="No Appointments For This Day"
                            />
                        </div>
                    )}
            </>


            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description">
                        Are you sure you want to cancel this
                        appointment
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleDelete()}
                        color="primary"
                    >
                        yes
                    </Button>
                    <Button
                        onClick={handleCloseDelete}
                        color="primary"
                        autoFocus
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>

        </Grid>
    );
};

export default Appointments;
