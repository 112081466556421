export const DASHBOARD = '/dashboard';
export const LOGIN = '/login';
export const OnlineHour = '/OnlineHour';
export const INSURANCE = '/insurance';
export const PATIENT = '/patient';
export const PAYMENT = '/payment';
export const ONLINE ='/OnlineHours';
export const APPOITMENT ='/appointments';
export const PROFILE='/PatientProfile';
export const CREATINVOICES ='/ViewInvoices/GenerateInvoices';
export const  VIEWINVOICES ='/ViewInvoices';
export const  INVOICES ='/Invoices';
export const ADMIN ='/Admins';
export const APPOINTMENT ='/appointment';
