import React from 'react';
import {Drawer, Theme, Hidden} from '@material-ui/core';
import {makeStyles, createStyles} from '@material-ui/core/styles';

import DrawerContent, {drawerWidth} from '../DrawerContent';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      drawer: {
        backgroundColor: theme.palette.primary.main,
        width: drawerWidth,
      },
    }),
);

const DesktopDrawer: React.FC = () => {
  const classes = useStyles();
  return (
      <Hidden smDown implementation="css">
        <Drawer open variant="permanent" classes={{paper: classes.drawer}}>
          <DrawerContent/>
        </Drawer>
      </Hidden>
  );
};
export default DesktopDrawer;
