import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import {
  Grid,
  makeStyles,
  createStyles,
  Typography,
} from '@material-ui/core';
import FirebaseUtil from '../../config/firebase';
import { TableSpiner } from '../../components';

const useStyles = makeStyles(() =>
  createStyles({
    payBtnWrapper: {
      marginBottom: '2rem',
    },
    label: {
      fontFamily: 'sans-serif',
      textAlign: 'center',
      padding: '0.2rem',
      borderRadius: '2px',
      margin: 'auto',
    },
    paidLabel: {
      border: '1px solid green',
      color: 'green',
    },
    unpaidLabel: {
      border: '1px solid red',
      color: 'red',
    },
  }),
);
enum invoiceEnum {
  PAID = 'paid',
  UNPAID = 'unpaid',
}

const InvoicePage: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [invoice, setInvoice] = useState<any>();

  useEffect(() => {
    (async () => {
      const invoiceRef = await FirebaseUtil.firestore
        .collection('invoices')
        .doc(id)
        .get();
      if (invoiceRef.exists) {
        setInvoice({
          id: invoiceRef.id,
          ...invoiceRef.data(),
        });
      }
    })();
  }, [id]);

  return (
    <>
      {invoice ? (
        <>
          <Grid container>
            <Grid
              className={classes.payBtnWrapper}
              item
              container
              justify="flex-end"
              xs={12}
            >
              <Typography
                className={clsx(
                  classes.label,
                  invoice.status === invoiceEnum.PAID
                    ? classes.paidLabel
                    : classes.unpaidLabel,
                )}
                component="p"
                variant="h6"
              >
                {invoice.status === invoiceEnum.PAID ? 'PAID' : 'UNPAID'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <embed
                src={invoice.invoiceUrl}
                type="application/pdf"
                width="100%"
                height="800px"
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <TableSpiner />
      )}
    </>
  );
};

export default InvoicePage;
