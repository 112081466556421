import React, { useContext } from 'react';
import { RouteProps, Redirect, Route } from 'react-router-dom';

import { UserContext } from '../../context/UserProvider';

const PrivateRoute: React.FC<RouteProps> = ({ component, ...rest }) => {
  const { role, isAuthenticated, authStateLoading } = useContext(UserContext);
  if (!isAuthenticated && !authStateLoading) return <Redirect to="/login" />;
  if (isAuthenticated && !authStateLoading && role !== 'admin')
    return <Redirect to="/login" />;
  else return <Route component={component} {...rest} />;
};

export default PrivateRoute;
