export const formatPhoneNo = (phone: string) => {
    const number = phone.split('-').join('');
    if (isNaN(Number(number))) {
        return '';
    }
    if (number.length <= 3) {
        return number;
    } else if (number.length <= 6) {
        return number.slice(0, 3) + '-' + number.slice(3, 6);
    } else {
        return (
            number.slice(0, 3) + '-' + number.slice(3, 6) + '-' + number.slice(6, 10)
        );
    }
};
