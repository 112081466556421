import React, {useState, useEffect} from 'react';
import {useSnackbar} from 'notistack';
import {
     dateStringToFirestoreTimeStamp,
     firestoreTimeStampToMUIDate2,
} from '../../utilities/firestoreDates';
import {
     Typography,
     Button,
     Stepper,
     Step,
     StepLabel,
     Grid, CircularProgress,
} from '@material-ui/core';
import {
     Formik,
     Form,
     FormikErrors,
     FormikTouched,
     FormikHelpers,
} from 'formik';
import UserDataForm from './UserDataForm';
import StatusForm from './StatusForm';
import AddressForm from './AddressForm';
import userDataSchema from './UserDataForm/userData.schema';
import userStatusDataSchema from './StatusForm/statusForm.schema';
import addressFormSchema from './AddressForm/addressForm.schema';
import {patientConverter, PatinetType, Patient} from '../../models/patient';
import FirebaseUtil from '../../config/firebase';
import './style.css';
import axios from 'axios';
import config from '../../config';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {CustomButton} from '../../components';
import comonSnackbarOpts from '../../utilities/comonSnackBarOpt';

interface Props {
     title?: string;
     patientId?: string;
     handleClose: () => any;
     setLoading: any;

     [x: string]: any;
}

export const useStyles = makeStyles(() =>
   createStyles({
        loader: {
             height: '300px',
             display: 'flex',
             alignItems: 'center',
             justifyContent: 'center',
        },
   }),
);

export type PatientStepFormValues = Omit<PatinetType, 'dateOfBirth'> & {
     birthDate: string; // we can't change the type, so we added a new property
     /* addressPick: 'newAddress' | 'existingAddress' | string;*/
     /*default?: boolean;*/
     [x: string]: any;
};

const lastStep = 2;
const steps = ['Personal data', 'Status and contact info', 'Address'];

const RegisterForm: React.FC<Props> = ({
     title,
     patientId,
     handleClose,
     setLoading,
}) => {
     const {enqueueSnackbar} = useSnackbar();
     const classes = useStyles();
     const [patientFormValues, setPatientFormValues] = useState<PatientStepFormValues | undefined>();
     const [step, setStep] = useState(0);
     const [loading, setLoadingForm] = useState(true);
     const [email, setEmail] = useState('');

     useEffect(() => {
          (async () => {
               if (!patientId) {
                    setLoadingForm(false);
                    return;
               }
               const data = await FirebaseUtil.firestore
                  .collection('patients')
                  .doc(patientId)
                  .withConverter(patientConverter)
                  .get();

               const patientData: PatinetType = data.data() as any;
               try {
                    const token = await FirebaseUtil.auth.currentUser?.getIdToken(false);

                    const response = await axios.post(
                       `${config.baseUrl}/decryptData`,
                       {
                            ssn: patientData.SSN,
                       },
                       {
                            headers: {
                                 authorization: token,
                            },
                       },
                    );
                    patientData.SSN = response.data.data;
               } catch (e) {

               }

               const {
                    firstName,
                    middleName,
                    lastName,
                    dateOfBirth,
                    gender,
                    address1,
                    address2,
                    city,
                    state,
                    zipCode,
                    SSN,
                    martialStatus,
                    prefaredLang,
                    race,
                    homePhone,
                    cellPhone,
               } = patientData;

               setPatientFormValues({
                    firstName,
                    middleName,
                    lastName,
                    birthDate: firestoreTimeStampToMUIDate2(dateOfBirth),
                    gender,
                    SSN,
                    race,
                    martialStatus,
                    prefaredLang,
                    homePhone,
                    cellPhone,
                    address1,
                    address2,
                    city,
                    state,
                    zipCode,
                    /*addressPick: 'newAddress',*/
               });
               setLoadingForm(false);
          })();
     }, [patientId]);

     const handleNext = () => {

          if (step === 0) {
               FirebaseUtil.firestore.collection('users')
                  .where('email', '==', email)
                  .get()
                  .then((doc) => {
                       if (!doc.empty) {
                            enqueueSnackbar('Email already exist', {
                                 variant: 'error',
                                 ...comonSnackbarOpts,
                            });
                            return;
                       } else {
                            setStep(step => step + 1);
                       }
                  });

          } else {
               setStep(step => step + 1);
          }
     };

     const handlePrev = () => {
          setStep(step => step - 1);
     };

     const isLastStep = (st: string | number) => {
          return Number(st) === lastStep;
     };

     const renderStep = (
        step: number,
        errors: FormikErrors<PatientStepFormValues>,
        touched: FormikTouched<PatientStepFormValues>,
        values: PatientStepFormValues,
        setValues: (
           values: PatientStepFormValues,
           shouldValidate?: boolean | undefined,
        ) => void,
        setFieldValue: any,
     ) => {
          switch (step) {
               case 0:
                    return (
                       <UserDataForm
                          values={values}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          setEmail={setEmail}
                          patientId={patientId}
                       />
                    );
               case 1:
                    return (
                       <StatusForm
                          errors={errors}
                          touched={touched}
                          values={values}
                          setFieldValue={setFieldValue}
                       />
                    );

               case 2:
                    return (
                       <AddressForm
                          errors={errors}
                          touched={touched}
                          values={values}
                          setValues={setValues}
                          setFieldValue={setFieldValue}
                       />
                    );
          }
     };

     const getWizardSchema = (step: number) => {
          switch (step) {
               case 0:
                    return userDataSchema;
               case 1:
                    return userStatusDataSchema;
               case 2:
                    return addressFormSchema;
          }
     };

     const handleSubmit = async (
        values: PatientStepFormValues,
        formikBag: FormikHelpers<PatientStepFormValues>,
     ) => {
          if (step === lastStep) {
               /*                let addressExist=false;


                               if(!addressExist){
                                   await FirebaseUtil.firestore
                                       .collection('users')
                                       .doc(user?.id)
                                       .update({
                                           addresses: firestore.FieldValue.arrayUnion({
                                               id: uuid(),
                                               address1: values.address1,
                                               address2: values.address2,
                                               state: values.state,
                                               city: values.city,
                                               zipCode: values.zipCode,
                                              /!* default: values.default,*!/
                                           }),
                                       });
                               }*/

               /*delete values.addressPick;*/
               /*delete values.default;*/
               /*delete values.saveAddress;*/

               const dateToTimeStamp = dateStringToFirestoreTimeStamp(values.birthDate);
               delete (values as any).birthDate;

               let patient_id = patientId;
               try {
                    if (patient_id) {
                         await FirebaseUtil.firestore
                            .collection('patients')
                            .doc(patient_id)
                            .withConverter(patientConverter)
                            .set({...values, dateOfBirth: dateToTimeStamp}, {merge: true});
                    } else {
                         const newUser = await FirebaseUtil.signUp(values.email, values.email);

                         await FirebaseUtil.firestore.collection('users')
                            .doc(newUser.user?.uid)
                            .set({
                                 email: values.email,
                                 name: values.firstName + ' ' + values.lastName,
                                 addresses: [],
                                 patientsIds: [],
                                 insurances: [],
                            });

                         FirebaseUtil.auth.sendSignInLinkToEmail(values.email, {
                              url: config.emailUrl,
                              handleCodeInApp: true,
                         })
                            .then(() => {
                            })
                            .catch((error) => {
                                 let errorMessage = error.message;
                                 console.log(errorMessage);
                            });

                         const data: any = {
                              ...values,
                              dateOfBirth: dateToTimeStamp,
                              userId: newUser.user?.uid,
                         };

                         const doc = await FirebaseUtil.firestore
                            .collection('patients')
                            .withConverter(patientConverter)
                            .add({
                                 ...data as Patient,
                                 isDeleted: false,
                            });
                         patient_id = doc.id;
                    }
                    if (values.SSN) {
                         const token = await FirebaseUtil.auth.currentUser?.getIdToken(false);
                         await axios.post(
                            `${config.baseUrl}/encryptData`,
                            {
                                 ssn: values.SSN,
                                 patientId: patient_id,
                            },
                            {
                                 headers: {
                                      authorization: token,
                                 },
                            },
                         );
                    }

                    handleClose();
                    setLoading(true);

                    enqueueSnackbar(
                       `Patient ${patientId ? 'updated' : 'added'} successfully`,
                       {
                            variant: 'success',
                            autoHideDuration: 3000,
                            preventDuplicate: true,

                            anchorOrigin: {horizontal: 'right', vertical: 'top'},
                       },
                    );
               } catch (e) {
                    enqueueSnackbar(e.message || 'Something went wrong', {
                         variant: 'error',
                         ...comonSnackbarOpts,
                    });
               }
          } else {
               await handleNext();
               formikBag.setSubmitting(false);
               formikBag.setTouched({});
               return;
          }
     };

     return (
        <div>
             <Typography
                style={{marginTop: '39px'}}
                variant="h6"
                component="h3"
                align="center"
             >
                  {title}
             </Typography>
             <Stepper activeStep={step} alternativeLabel>
                  {steps.map(label => (
                     <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                     </Step>
                  ))}
             </Stepper>
             {loading ? (
                   <div className={classes.loader}><CircularProgress/></div>
                ) :
                <Formik
                   enableReinitialize
                   onSubmit={handleSubmit}
                   validationSchema={getWizardSchema(step)}
                   initialValues={
                        patientFormValues
                           ? {...patientFormValues /*addressPick: 'existingAddress'*/}
                           : {
                                firstName: '',
                                middleName: '',
                                lastName: '',
                                birthDate: '',
                                gender: '',
                                SSN: '',
                                race: '',
                                martialStatus: '',
                                prefaredLang: '',
                                homePhone: '',
                                cellPhone: '',
                                address1: '',
                                address2: '',
                                city: '',
                                state: '',
                                zipCode: '',
                                /*default: false,*/
                                /*saveAddress: false,*/
                                /*addressPick: 'newAddress',*/
                           }
                   }
                >
                     {({
                          values,
                          errors,
                          touched,
                          setValues,
                          setFieldValue,
                          isSubmitting,
                     }) => {
                          return (
                             <Form>
                                  {renderStep(
                                     step,
                                     errors,
                                     touched,
                                     values,
                                     setValues,
                                     setFieldValue,
                                  )}
                                  <Grid container justify="center" spacing={2}>
                                       {step >= 1 && (
                                          <Grid item xs={5}>
                                               <Button
                                                  style={{width: '100%'}}
                                                  variant="outlined"
                                                  color="primary"
                                                  onClick={handlePrev}
                                               >
                                                    Back
                                               </Button>
                                          </Grid>
                                       )}
                                       <Grid item xs={5}>
                                            <CustomButton
                                               style={{width: '100%'}}
                                               loading={isSubmitting}
                                               disabled={isSubmitting}
                                               type="submit"
                                               variant="contained"
                                               color="primary"
                                            >
                                                 {isLastStep(step)
                                                    ? patientId
                                                       ? 'Update'
                                                       : 'Submit'
                                                    : 'Save & Next'}
                                            </CustomButton>
                                       </Grid>
                                  </Grid>
                             </Form>
                          );
                     }}
                </Formik>
             }

        </div>
     );
};

export default RegisterForm;
