import React, {useState} from 'react';

import {
  Typography,
  makeStyles,
  createStyles,
  Grid,
  Button,
} from '@material-ui/core';

import {Formik, Field, Form, FormikHelpers} from 'formik';
import CustomButton from "../Button";
import CustomInput from '../CustomInput';
import {handleAuthErrors} from '../../utilities';
import FirebaseUtil from '../../config/firebase';
import schema from '../../containers/Login/login.schema';
import { useHistory } from 'react-router-dom';

export const useStyles = makeStyles(() =>
    createStyles({
      input: {minHeight: '82px'},
      form: {width: '100%'},
      uppercase: {
        textTransform: 'uppercase',
      },
      errorMsg: {
        fontSize: '14px',
        background: '#FFC199',
        padding: '0.25rem 0.5rem',
        borderRadius: '3px',
      },
      errWrapper: {
        minHeight: '45px',
      },
    }),
);

interface FormValues {
  email: string;
  password: string;
}

const LoginForm: React.FC = () => {
  const classes = useStyles();
  const [error, setError] = useState<string | undefined>(undefined);
  const history = useHistory();

  const handleSubmit = async (values: FormValues) => {
    try {

     await FirebaseUtil.signIn(values.email, values.password).then(result => {
        console.warn(result.user)
        console.log('logged in');
        result?.user?.getIdTokenResult()
        .then((idTokenResult) => {
          console.log(idTokenResult.claims.admin);
             if (!idTokenResult.claims.admin) {
                  setError("You are not an Admin!");
             }else{
              history.push('/dashboard');
             }
        })
      })
      .catch(err => {
        console.error(err)
        throw err;
      });
    
      // if (!user) setError('User not found');
      // else if (user && !user.emailVerified) {
      //   setError('Please verify your email');
      // }
        //  user?.getIdTokenResult()
        //     .then((idTokenResult) => {
        //          if (!idTokenResult.claims.admin) {
        //               setError("You are not an Admin!");
        //          }
        //     })
    } catch (e) {

      if (e.code == 'auth/wrong-password') {
       console.error('Incorrect password')
      } else {
        console.error('You are not authorized');
      }    
    }
  };

  return (
      <Grid item spacing={2} xs={8} container>
        <Grid item xs={12}>
          <img src={require('../../Images/logo1.png')} alt="logo"/>
        </Grid>
        <Grid item xs={12}>
          <Typography component="h2" variant="h2">
            Sign IN
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="p" variant="h6">
            Please sign in to your dashboard
          </Typography>
        </Grid>
        <Grid className={classes.errWrapper} item xs={12}>
          {error && (
              <Typography
                  className={classes.errorMsg}
                  color="error"
                  component="p"
                  variant="h6"
              >
                {error}
              </Typography>
          )}
        </Grid>
        <Grid item xs={12} container>
          <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              onSubmit={handleSubmit}
              validationSchema={schema}
          >
            {({errors, touched, isSubmitting, handleSubmit}) => (
                <Form className={classes.form} onSubmit={handleSubmit}>
                  <Grid className={classes.input} item xs={12}>
                    <Field
                        fullWidth
                        variant="filled"
                        label="Email *"
                        name="email"
                        as={CustomInput}
                        error={touched.email && errors.email && true}
                        helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid className={classes.input} item xs={12}>
                    <Field
                        fullWidth
                        variant="filled"
                        label="Password *"
                        name="password"
                        type="password"
                        as={CustomInput}
                        error={touched.password && errors.password && true}
                        helperText={touched.password && errors.password}
                    />
                  </Grid>

                  <Grid
                      item
                      container
                      spacing={2}
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      xs={12}
                  >
                    <Grid item xs>
                      <CustomButton
                          type="submit"
                          color="primary"
                          size="small"
                          variant="contained"
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          disableElevation
                      >
                        Sign in
                      </CustomButton>
                    </Grid>
                  </Grid>
                </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
  );
};

export default LoginForm;
