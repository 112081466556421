import React, {useCallback, useEffect, useState} from 'react';
import clsx from 'clsx';
import {
     Box,
     Card,
     CardActions,
     CardContent,
     Divider,
     Typography,
     makeStyles,
     Grid, Backdrop, CircularProgress,
} from '@material-ui/core';
import {patientConverter, PatinetType} from '../models/patient';
import FirebaseUtil from '../config/firebase';
import {useParams} from 'react-router-dom';
import uppercaseFirst from '../utilities/uppercaseFirst';
import axios from 'axios';
import config from '../config';

const useStyles = makeStyles((theme) => ({
     root: {
          margin: '40px',
          height: 500,
          width: 900,
     },
     bigLoader: {
          display: 'block',
          margin: 'auto',
          width: '180px !important',
          height: 'auto !important',
     },
     backdrop: {
          zIndex: theme.zIndex.drawer + 1,
          color: theme.palette.primary.main,
     },
}));

interface Props {
     patientId: string;
     className?: string;
}

const PatientProfile: React.FC<Props> = ({className}) => {
     const classes = useStyles();
     const [patient, setPatient] = useState<PatinetType>();
     const [loadingProfile, setLoadingProfile] = useState(false);
     const {id} = useParams<{ id: string }>();

     useEffect(() => {
          (async () => {
               setLoadingProfile(true)
               const patientData = await fetchProfile();
               try {
                    const token = await FirebaseUtil.auth.currentUser?.getIdToken(false);
                    const response = await axios.post(
                       `${config.baseUrl}/decryptData`,
                       {
                            ssn: patientData?.SSN,
                       },
                       {
                            headers: {
                                 authorization: token,
                            },
                       },
                    );
                    // @ts-ignore
                    patientData.SSN = response.data.data;
               } catch (e) {
                    console.log(JSON.stringify(e?.response?.data?.message));
               }
               setLoadingProfile(false)
               setPatient(patientData);
          })();
     }, []);

     const fetchProfile = useCallback(async () => {
          const patientsRef = await FirebaseUtil.firestore
             .collection('patients')
             .doc(id)
             .withConverter(patientConverter)
             .get();

          return patientsRef.data();
     }, []);

     function getDate(dateOfBirth: any) {
          if (dateOfBirth.seconds) {
               let epoch = new Date(1970, 0, 1);
               epoch.setSeconds(dateOfBirth.seconds);
               let updatedDate = new Date(epoch);
               return `${(updatedDate.getMonth() + 1).toString()
                  .padStart(2, '0')}/${(updatedDate.getDate()).toString()
                  .padStart(2, '0')}/${updatedDate.getFullYear()}`;
          }
     }

     return (
        <>
             {loadingProfile &&
             <Backdrop className={classes.backdrop} open={true}>
               <CircularProgress className={classes.bigLoader}/>
             </Backdrop>
             }

             <Card
                className={clsx(classes.root, className)}
             >  {patient && (<>
                  <CardContent style={{backgroundColor: '#A01414'}}>
                       <Box
                          alignItems="center"
                          display="flex"
                          flexDirection="column"
                       >
                            <Typography
                               style={{color: '#ffffff'}}
                               gutterBottom
                               variant="h3"
                            >
                                 {patient.firstName} {patient.middleName} {patient.lastName}
                            </Typography>
                       </Box>
                  </CardContent>
                  <Divider/>
                  <CardActions>
                       <br/>
                       <br/>
                       <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                                 <Typography
                                    style={{color: '#A01414'}}
                                    variant="body1"
                                 >
                                      Date Of Birth
                                 </Typography>
                                 <Typography
                                    style={{color: '#212121'}}
                                    variant="body1"
                                 >
                                      {(patient.dateOfBirth) ? getDate(patient.dateOfBirth) : ''}
                                 </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                 <Typography
                                    style={{color: '#A01414'}}
                                    variant="body1"
                                 >
                                      Gender
                                 </Typography>
                                 <Typography
                                    style={{color: '#212121'}}
                                    variant="body1"
                                 >
                                      {uppercaseFirst(patient.gender)}
                                 </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                 <Typography
                                    style={{color: '#A01414'}}
                                    variant="body1"
                                 >

                                      Cell Phone
                                 </Typography>
                                 <Typography
                                    style={{color: '#212121'}}
                                    variant="body1"
                                 >
                                      {patient.cellPhone}
                                 </Typography>

                            </Grid>
                            <Grid item md={6} xs={12}>
                                 <Typography
                                    style={{color: '#A01414'}}
                                    variant="body1"
                                 >
                                      Preferred Language
                                 </Typography>
                                 <Typography
                                    style={{color: '#212121'}}
                                    variant="body1"
                                 >
                                      {uppercaseFirst(patient.prefaredLang)}
                                 </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                 <Typography
                                    style={{color: '#A01414'}}
                                    variant="body1"
                                 >
                                      Home Phone
                                 </Typography>
                                 <Typography
                                    style={{color: '#212121'}}
                                    variant="body1"
                                 >
                                      {(patient.homePhone === '')
                                         ? 'No home Phone '
                                         : patient.homePhone}
                                 </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                 <Typography
                                    style={{color: '#A01414'}}
                                    variant="body1"
                                 >
                                      Martial Status
                                 </Typography>
                                 <Typography
                                    style={{color: '#212121'}}
                                    variant="body1"
                                 >
                                      {uppercaseFirst(patient.martialStatus)}
                                 </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                 <Typography
                                    style={{color: '#A01414'}}
                                    variant="body1"
                                 >
                                      Address
                                 </Typography>
                                 <Typography
                                    style={{color: '#212121'}}
                                    variant="body1"
                                 >
                                      {patient.address1 + '\n'}
                                      <br/>{`${patient.city}, ${patient.state} ${patient.zipCode}`}
                                 </Typography>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                 <Typography
                                    style={{color: '#A01414'}}
                                    variant="body1"
                                 >
                                      Race
                                 </Typography>
                                 <Typography
                                    style={{color: '#212121'}}
                                    variant="body1"
                                 >
                                      {uppercaseFirst(patient.race)}
                                 </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                 <Typography
                                    style={{color: '#A01414'}}
                                    variant="body1"
                                 >
                                      SSN
                                 </Typography>
                                 <Typography
                                    style={{color: '#212121'}}
                                    variant="body1"
                                 >
                                      {patient.SSN ?
                                         `${patient.SSN}`
                                         : 'None'}

                                 </Typography>
                            </Grid>
                       </Grid>
                  </CardActions>
             </>)}
             </Card>
        </>
     );
};

export default PatientProfile;
