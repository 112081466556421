import React, { useState } from 'react';
import { Grid, Typography, Card, CardContent } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';

import CustomInput from '../CustomInput';
import { PatientStepFormValues } from '../../containers/PatientForm/index';

const searchOptions = {
  types: ['address'],
  componentRestrictions: { country: 'us' },
};

interface Props {
  values: PatientStepFormValues;
  setValues?: (
    values: PatientStepFormValues,
    shouldValidate?: boolean | undefined,
  ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionsCard: {
      position: 'relative',
    },
    option: {
      cursor: 'pointer',
      width: '100%',
      '&:hover': {
        background: '#ddd',
      },
      marginBottom: '0.25rem',
    },
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
    },
    mainText: {
      fontWeight: 'bold',
    },
  }),
);

const AddressPickerWithGoogle: React.FC<Props> = ({ values, setValues }) => {
  const classes = useStyles();
  const [googleAddress, setGoogleAddress] = useState('');
  const [showOptions, setShowOptions] = useState(false);

  const handleSelect = async (address: any) => {
    setShowOptions(false);
    setGoogleAddress(address);

    let address1 = '';
    let address2 = '';
    let city = '';
    let state = '';
    let zipCode = '';

    const geoCode = await geocodeByAddress(address);
    geoCode[0]['address_components'].forEach((addressComponent: any) => {
      const { types } = addressComponent;
      if (types.includes('route')) {
        address1 += addressComponent['short_name'];
      }
      if (types.includes('street_number')) {
        address1 += ' ' + addressComponent['short_name'];
      }

      if (types.includes('postal_code')) {
        zipCode = addressComponent['short_name'];
      }
      if (types.includes('administrative_area_level_1')) {
        state = addressComponent['short_name'];
      }
      if (
        types.includes('sublocality_level_1') ||
        types.includes('administrative_area_level_3') ||
        types.includes('locality') ||
        types.includes('sublocality_level_1')
      ) {
        city = addressComponent['short_name'];
      }
    });
    if (values && setValues) {
      setGoogleAddress('');
      setValues({ ...values, address1, state, address2, city, zipCode });
    }
  };

  return (
    <PlacesAutocomplete
      searchOptions={searchOptions}
      value={googleAddress}
      onChange={value => {
        if (value) setShowOptions(true);
        else setShowOptions(false);
        setGoogleAddress(value);
      }}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div>
          <CustomInput
            fullWidth
            label="Search Address"
            variant="filled"
            name="googleSearchField"
            {...getInputProps({ placeholder: 'type to search' })}
            onBlur={() => {
              setShowOptions(false);
            }}
          />
          {showOptions && (
            <Card className={classes.optionsCard}>
              <CardContent>
                {suggestions.length === 0 ? (
                  <div>loading ...</div>
                ) : (
                  suggestions.map((s, index) => {
                    return (
                      <div
                        {...getSuggestionItemProps(s)}
                        key={index}
                        className={classes.option}
                      >
                        <Grid container alignItems="center">
                          <Grid item>
                            <LocationOnIcon className={classes.icon} />
                          </Grid>
                          <Grid item xs>
                            <Typography
                              variant="body1"
                              color="textPrimary"
                              className={classes.mainText}
                            >
                              {s.formattedSuggestion.mainText}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {s.formattedSuggestion.secondaryText}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  })
                )}
              </CardContent>
            </Card>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default AddressPickerWithGoogle;
