import React from 'react';
import { TextField, FilledTextFieldProps } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

interface Props extends FilledTextFieldProps {
     label: string;
}

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
        root: {
             overflow: 'hidden',
             borderRadius: 4,
             backgroundColor: '#fff',
             transition: theme.transitions.create(['border-color', 'box-shadow']),
             '& label.Mui-focused': {
                  color: theme.palette.primary.dark,
             },
             '&:hover': {
                  backgroundColor: '#fff',
             },
        },
        input: {
             padding: '21px 22px 17px 18px',
             fontFamily: "'Cairo',sans-serif",
             fontWeight: 'bold',
             fontSize: '15px',
        },
   }),
);

const CustomSelect: React.FC<Props> = ({ label, ...props }) => {
     const classes = useStyles();
     return (
        <TextField
           select
           className={classes.root}
           fullWidth
           label={label}
           {...props}
           variant="outlined"
        />
     );
};

export default CustomSelect;
