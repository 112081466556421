import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FirebaseUtil from '../../config/firebase';
import { format24To12 } from '../../utilities/format24To12';
import { extractTimeFromDate } from '../../utilities/extractTimeFromDate';
import { fireStoreTimeStampToMMDDYY } from '../../utilities/firebaseDates';
import {
	AppBar,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	InputAdornment,
	Link,
	Paper,
	Tab,
	Tabs,
	TextField,
	Typography,
} from '@material-ui/core';
import {
	dateToMMDDYY,
	fireStoreTimeStampToMUIDate,
} from '../../utilities/firestoreDates';
import formatAddress from '../../utilities/formatAddress';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';
import { BlobProvider } from '@react-pdf/renderer';
import PDFInvoice from '../../components/PDFInvoice';
import firebase, { app } from 'firebase';
import { v4 as uuid } from 'uuid';

import { useTheme } from '@material-ui/core/styles';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const a11yProps = (index: any) => {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
};

const TabPanel: React.FC<TabPanelProps> = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
};

const useStyles = makeStyles((theme) =>
	createStyles({
		logo: {
			objectFit: 'contain',
			width: '100%',
			height: 'auto',
		},
		loader: {
			width: 'initial !important',
			height: 'initial !important',
			marginRight: '6px',
			'& svg': {
				height: '27px !important',
			},
		},
		dialogPaperWidthSm: {
			maxWidth: '900px',
		},
		field: {
			whiteSpace: 'nowrap',
			color: '#a31b35',
			height: '50%',
			margin: '20px',
			width: '70%',
			'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
				{
					'-webkit-appearance': 'none',
					margin: 0,
				},
		},
		tab: {
			marginTop: '20px',
			backgroundColor: 'white',
			boxShadow: 'none',
			border: `none`,
			/*  width: '400px',
            paddingLeft: '24px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            }, */
		},
		tabPanel: {
			width: '100%',
		},
	})
);

const capitalize = (s: any) => (s && s[0].toUpperCase() + s.slice(1)) || '';

const Appointment = () => {
	const { id } = useParams<{ id: string }>();
	const classes = useStyles();
	const [appointment, setAppointment] = useState<any>(null);
	const [patient, setPatient] = useState<any>(null);
	const [patientOwner, setPatientOwner] = useState<any>(undefined);
	const [viewImage, setShowImageDialog] = useState(false);
	const [previewImg, setImg] = useState<any>('');
	const [invoiceRows, setInvoiceRows] = useState<any>([]);
	const [invoiceBlob, setInvoiceBlob] = useState<Blob | undefined>();
	const [open, setOpen] = useState(false);
	const [myInvoiceRef, setMyInvoiceRef] = useState<any>();
	const [error, setError] = useState(false);
	const [amount, setAmount] = useState<any>('');
	const [issueDate, setIssueDate] = useState<Date>(new Date());
	const [invoiceId, setInvoiceId] = useState<string>('');
	const [submited, setSubmited] = useState(false);
	const [hasInvoice, setHasInvoice] = useState(false);

	const [value, setValue] = React.useState(0);

	const theme = useTheme();

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
	};

	const handleChangeIndex = (index: number) => {
		setValue(index);
	};
	const checkHasInvoice = async (appointmentId: any) => {
		await FirebaseUtil.firestore
			.collection('invoices')
			.where('appointmentId', '==', appointmentId)
			.get()
			.then((aps) => {
				if (aps.empty) {
					setHasInvoice(false);
				} else {
					setHasInvoice(true);
				}
			});
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleAmount = (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		const { value } = e.target;
		if (value === '') setAmount('');
		if (Number(value)) {
			setAmount(Number(value));
			setError(false);
		}
	};

	useEffect(() => {
		if (id) {
			checkHasInvoice(id);
			fetchAppointment();
		}
	}, [id]);

	const fetchAppointment = () => {
		FirebaseUtil.firestore
			.collection('appointments')
			.doc(id)
			.get()
			.then((doc) => {
				if (doc.exists) {
					const data = doc.data();
					const fromTime = format24To12(
						extractTimeFromDate(data?.fromTime.toDate())
					);
					const toTime = format24To12(
						extractTimeFromDate(data?.toTime.toDate())
					);
					const day = fireStoreTimeStampToMMDDYY(data?.fromTime);
					const app = {
						...data,
						fromTime,
						toTime,
						day,
						id: doc.id,
						fromDate: data?.fromTime,
						toDate: data?.toTime,
					};
					setAppointment(app);

					fetchPatient(data?.patientId);
					fetchUser(data?.userId, data?.insuranceId);
				}
			});
	};

	const fetchPatient = async (uid: string) => {
		const patientRef = await FirebaseUtil.firestore
			.collection('patients')
			.doc(uid)
			.get();

		setPatient({ id: patientRef.id, ...patientRef.data() });
	};

	const fetchUser = async (userId: string, insuranceId: string) => {
		const userRef = await FirebaseUtil.firestore
			.collection('users')
			.doc(userId)
			.get();

		const userData = userRef.data();
		let insurance: any = null;
		userData?.insurances?.map((ins: any) => {
			if (ins.id == insuranceId) {
				insurance = {
					name: ins.name,
					groupNo: ins.groupNo,
					memberId: ins.memberId,
					effectiveDate: ins.effectiveDate,
					image: ins.image,
					responsibleParty: ins.responsibleParty,
				};
			}
		});
		setPatientOwner({
			id: userRef.id,
			...userRef.data(),
			insurance: insurance,
		});
	};

	function getDate(dateOfBirth: any) {
		return dateToMMDDYY(dateOfBirth.toDate());
	}

	const handleClickView = (src: string) => {
		setImg(src);
		setShowImageDialog(true);
	};

	const handleAddInvoices = async (appointment: any) => {
		const invoiceRef = FirebaseUtil.firestore.collection('invoices').doc();
		setMyInvoiceRef(invoiceRef);

		const numberAmount = Number(amount) * 100;

		const { address1, address2 } = patient;
		const { description, reasonForVisit, firstName, middleName, lastName } =
			appointment;

		const db = FirebaseUtil.firestore;
		const countRef = FirebaseUtil.firestore
			.collection('invoices')
			.doc('--stats--');
		const _invoiceRef = invoiceRef;

		await db.runTransaction(async (trans) => {
			const countDataRef = await trans.get(countRef);
			let count = countDataRef.data()?.invoicesCount;

			if (!count) {
				trans.set(
					countRef,
					{
						invoicesCount: 1,
					},
					{
						merge: true,
					}
				);
				count = 1;
				setInvoiceId(count);
			} else {
				trans.set(
					countRef,
					{
						invoicesCount: firebase.firestore.FieldValue.increment(1),
					},
					{
						merge: true,
					}
				);
				setInvoiceId(count + 1);
				count += 1;
			}

			trans.set(_invoiceRef, {
				invoiceNo: count,
				issueDate,
				dueDate: appointment.toDate,
				amount: numberAmount,
				address1,
				address2,
				description,
				reasonForVisit,
				firstName,
				middleName,
				lastName,
				appointmentId: appointment.id,
				userId: patientOwner.id,
				patientId: patient.id,
				status: 'unpaid',
				isDeleted: false,
			});
		});
	};

	useEffect(() => {
		(async () => {
			if (invoiceId) {
				setSubmited(false);
				let url: string = '';
				if (invoiceBlob) url = await uploadInvoice(invoiceBlob);
				await myInvoiceRef.set(
					{
						invoiceUrl: url,
					},
					{ merge: true }
				);
				setSubmited(false);
				setHasInvoice(true);
				setInvoiceId('');
				setAmount('');

				setOpen(false);
				setInvoiceRows([]);
			}
		})();
	}, [invoiceBlob]);

	const uploadInvoice = useCallback(
		async (blob: Blob) => {
			const uploadRef = await FirebaseUtil.storage
				.ref()
				.child(`usersInvoices/${patientOwner?.id}/${uuid()}.pdf`);
			await uploadRef.put(blob);
			return uploadRef.getDownloadURL();
		},
		[patientOwner]
	);

	useEffect(() => {
		(async () => {
			if (patient && appointment) {
				await setInvoiceRows([
					{
						service: 'Appointment',
						patient: patient.firstName + ' ' + patient.lastName,
						date: dateToMMDDYY(appointment.fromDate.toDate()),
						reason: `${appointment.reasonForVisit}`,
						price: Number(amount),
					},
				]);
			}
		})();
	}, [patient, amount]);

	return (
		<div>
			{appointment && patient && patientOwner && (
				<>
					<Grid
						container
						justify={'space-between'}
						alignItems={'center'}
						style={{ marginBottom: '20px' }}>
						<RouterLink to={'/dashboard/appointments'}>
							<Button
								variant={'text'}
								style={{ display: 'flex', alignItems: 'center' }}>
								<ArrowBack fontSize={'small'} />
								<Typography variant='body2' color='textPrimary' component='p'>
									Back to appointments
								</Typography>
							</Button>
						</RouterLink>

						<Button
							variant='contained'
							color='primary'
							disableElevation
							onClick={handleClickOpen}
							disabled={hasInvoice}>
							Add invoice
						</Button>

						<Grid
							container
							justify={'space-between'}
							alignItems={'center'}
							style={{ marginBottom: '20px' }}>
							<AppBar position='static' color='default' className={classes.tab}>
								<Tabs
									value={value}
									onChange={handleChange}
									indicatorColor='primary'
									variant='fullWidth'
									aria-label='full width tabs example'>
									<Tab
										style={{ textTransform: 'none' }}
										label={
											<Typography
												component='p'
												variant='body1'
												style={{
													fontWeight: 700,
													color: theme.palette.primary.main,
												}}>
												General Infromation
											</Typography>
										}
										{...a11yProps(0)}
									/>
									<Tab
										style={{ textTransform: 'none' }}
										label={
											<Typography
												component='p'
												variant='body1'
												style={{
													fontWeight: 700,
													color: theme.palette.primary.main,
												}}>
												Parent Information
											</Typography>
										}
										{...a11yProps(1)}
									/>
								</Tabs>
							</AppBar>

							<TabPanel value={value} index={0}>
								<Paper style={{ padding: '40px', width: '70vw' }} elevation={5}>
									<Typography variant='h6' component='h3'>
										Patient Info
									</Typography>

									<Grid container style={{ marginTop: '20px' }}>
										<Grid item xs={12} sm={4}>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textPrimary'
												component='p'>
												Name:
											</Typography>
											<Typography>
												{patient.firstName +
													' ' +
													patient.middleName +
													' ' +
													patient.lastName}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={4}>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textPrimary'
												component='p'>
												Birthdate:
											</Typography>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textSecondary'
												component='p'>
												{patient.dateOfBirth
													? getDate(patient.dateOfBirth)
													: ''}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={4}>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textPrimary'
												component='p'>
												Cell Phone:
											</Typography>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textSecondary'
												component='p'>
												{patient.cellPhone}
											</Typography>
										</Grid>
									</Grid>
									<Grid container style={{ marginTop: '15px' }}>
										<Grid item xs={12} sm={4}>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textPrimary'
												component='p'>
												Address:
											</Typography>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textSecondary'
												component='p'>
												{formatAddress({
													address1: patient.address1,
													address2: patient.address2,
													city: patient.city,
													zipCode: patient.zipCode,
												})}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={4}>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textPrimary'
												component='p'>
												Race:
											</Typography>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textSecondary'
												component='p'>
												{patient.race}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={4}>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textPrimary'
												component='p'>
												Martial Status:
											</Typography>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textSecondary'
												component='p'>
												{capitalize(patient.martialStatus)}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={4}>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textPrimary'
												component='p'>
												Preferred Language:
											</Typography>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textSecondary'
												component='p'>
												{capitalize(patient.prefaredLang)}
											</Typography>
										</Grid>
									</Grid>
								</Paper>
								<Paper
									style={{ padding: '40px', marginTop: '40px', width: '70vw' }}
									elevation={5}>
									<Typography variant='h6' component='h3'>
										Appointment
									</Typography>

									<Grid container style={{ marginTop: '20px' }}>
										<Grid item xs={12} sm={4}>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textPrimary'
												component='p'>
												Appointment Date:
											</Typography>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textSecondary'
												component='p'>
												{appointment.day}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={4}>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textPrimary'
												component='p'>
												Appointment Time:
											</Typography>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textSecondary'
												component='p'>
												{appointment.fromTime + ' - ' + appointment.toTime}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={4}>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textPrimary'
												component='p'>
												Reason:
											</Typography>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textSecondary'
												component='p'>
												{appointment.reasonForVisit}
											</Typography>
										</Grid>
									</Grid>
									<Grid container style={{ marginTop: '15px' }}>
										<Grid item xs={12} sm={4}>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textPrimary'
												component='p'>
												Flu Shot:
											</Typography>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textSecondary'
												component='p'>
												{appointment.wantFluShot
													? 'Interested'
													: 'Not Interested'}
											</Typography>
										</Grid>
										<Grid item xs={12} style={{ marginTop: '15px' }}>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textPrimary'
												component='p'>
												Description:
											</Typography>
											<Typography
												style={{
													margin: '5px',
													fontWeight: 'bold',
													overflowWrap: 'break-word',
												}}
												variant='body1'
												color='textSecondary'
												component='p'>
												{appointment.description}
											</Typography>
										</Grid>
									</Grid>
								</Paper>

								<Paper
									style={{ padding: '40px', margin: '40px 0', width: '70vw' }}
									elevation={5}>
									<Typography variant='h6' component='h3'>
										Insurance
									</Typography>
									{patientOwner.insurance ? (
										<>
											<Grid container style={{ marginTop: '20px' }}>
												<Grid item xs={12} sm={4}>
													<Typography
														style={{ margin: '5px', fontWeight: 'bold' }}
														variant='body1'
														color='textPrimary'
														component='p'>
														Insurance Name:
													</Typography>
													<Typography
														style={{ margin: '5px', fontWeight: 'bold' }}
														variant='body1'
														color='textSecondary'
														component='p'>
														{patientOwner.insurance.name}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={4}>
													<Typography
														style={{ margin: '5px', fontWeight: 'bold' }}
														variant='body1'
														color='textPrimary'
														component='p'>
														Group Number:
													</Typography>
													<Typography
														style={{ margin: '5px', fontWeight: 'bold' }}
														variant='body1'
														color='textSecondary'
														component='p'>
														{patientOwner.insurance.groupNo}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={4}>
													<Typography
														style={{ margin: '5px', fontWeight: 'bold' }}
														variant='body1'
														color='textPrimary'
														component='p'>
														Member Id:
													</Typography>
													<Typography
														style={{ margin: '5px', fontWeight: 'bold' }}
														variant='body1'
														color='textSecondary'
														component='p'>
														{patientOwner.insurance.memberId}
													</Typography>
												</Grid>
											</Grid>
											<Grid container style={{ marginTop: '15px' }}>
												<Grid item xs={12} sm={4}>
													<Typography
														style={{ margin: '5px', fontWeight: 'bold' }}
														variant='body1'
														color='textPrimary'
														component='p'>
														Effective Date:
													</Typography>
													<Typography
														style={{ margin: '5px', fontWeight: 'bold' }}
														variant='body1'
														color='textSecondary'
														component='p'>
														{fireStoreTimeStampToMUIDate(
															patientOwner.insurance.effectiveDate
														)}
													</Typography>
												</Grid>
												<Grid container style={{ marginTop: '15px' }}>
													<Grid item xs={12} style={{ marginTop: '15px' }}>
														<Typography
															style={{ margin: '5px', fontWeight: 'bold' }}
															variant='body1'
															color='textPrimary'
															component='p'>
															Insurance Card Image:
														</Typography>
														<Typography
															style={{ margin: '5px', fontWeight: 'bold' }}
															variant='body1'
															color='textSecondary'
															component='p'>
															{patientOwner.insurance.image ? (
																<Link
																	component='button'
																	variant='body2'
																	onClick={() => {
																		handleClickView(
																			patientOwner.insurance.image
																		);
																	}}>
																	Click to view
																</Link>
															) : (
																'None'
															)}
														</Typography>
													</Grid>
													<Grid item xs={12} style={{ marginTop: '15px' }}>
														<Typography
															style={{ margin: '5px', fontWeight: 'bold' }}
															variant='body1'
															color='textPrimary'
															component='p'>
															Responsible Party:
														</Typography>
														<Typography
															style={{ margin: '5px', fontWeight: 'bold' }}
															variant='body1'
															color='textSecondary'
															component='p'>
															{patientOwner?.insurance?.responsibleParty
																? patientOwner?.insurance?.responsibleParty
																: 'None'}
														</Typography>
													</Grid>
												</Grid>
											</Grid>
										</>
									) : (
										<Typography
											style={{ marginTop: '15px', fontWeight: 'bold' }}
											variant='body1'
											color='textSecondary'
											component='p'>
											No Insurance Provided
										</Typography>
									)}
								</Paper>
							</TabPanel>

							<TabPanel value={value} index={1}>
								<Paper
									style={{ padding: '40px', margin: '40px 0', width: '70vw' }}
									elevation={5}>
									<Typography variant='h6' component='h3'>
										Parent Info
									</Typography>

									<Grid
										container
										alignItems={'center'}
										style={{ marginBottom: '20px' }}>
										<Grid item xs={12} sm={4}>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textPrimary'
												component='p'>
												Name:
											</Typography>

											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textSecondary'
												component='p'>
												{patientOwner.name}
											</Typography>
										</Grid>

										<Grid item xs={12} sm={4} style={{ marginRight: '20px' }}>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textPrimary'
												component='p'>
												Email:
											</Typography>
											<Typography
												style={{ margin: '5px', fontWeight: 'bold' }}
												variant='body1'
												color='textSecondary'
												component='p'>
												{patientOwner.email}
											</Typography>
										</Grid>
									</Grid>
								</Paper>
							</TabPanel>
						</Grid>
					</Grid>
				</>
			)}
			<Dialog
				open={viewImage}
				onClose={() => setShowImageDialog(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				classes={{
					paperWidthSm: classes.dialogPaperWidthSm,
				}}>
				<DialogContent>
					<div>
						{!previewImg ? (
							<CircularProgress className={classes.loader} />
						) : (
							<img alt={'logo'} src={previewImg} className={classes.logo} />
						)}
					</div>
				</DialogContent>
			</Dialog>
			<Dialog open={open} aria-labelledby='Add Doctor'>
				<DialogTitle id='Add amount' style={{ color: '#a31b35' }}>
					Add Invoice
				</DialogTitle>
				<DialogContent>
					<Grid container direction='row'>
						<Grid container justify='space-around'>
							<TextField
								className={classes.field}
								required
								id='name'
								label=' Amount '
								type='number'
								fullWidth
								error={error}
								helperText={error && 'Please Enter Amount.'}
								onChange={handleAmount}
								value={amount}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>$</InputAdornment>
									),
								}}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='primary'>
						Cancel
					</Button>
					{submited && (
						<BlobProvider
							document={
								<PDFInvoice
									invoiceTableData={{ items: invoiceRows }}
									total={Number(amount)}
									invoiceSummary={{
										invoiceNo: invoiceId,
										invoiceDate: issueDate,
										dueDate: appointment.fromDate.toDate(),
										total: Number(amount),
									}}
									userAddressData={{
										address1: patient.address1,
										address2: patient.address2,
										email: patientOwner?.email,
										name: patientOwner ? patientOwner.name : '',
									}}
								/>
							}>
							{({ blob, loading, error }) => {
								if (!error && !loading && blob) {
									setInvoiceBlob(blob);
								}
								return <></>;
							}}
						</BlobProvider>
					)}
					<Button
						onClick={async () => {
							if (amount && amount != 0) {
								await handleAddInvoices(appointment);
								await setSubmited(true);
							} else setError(true);
						}}
						color='primary'>
						Add
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default Appointment;
