export const config = {
  firebase: {
    apiKey: 'AIzaSyCKV3VeK1cmjh57gKFp0YZlhSmRMKwYA6g',
    authDomain: 'urgent-care-ec38b.firebaseapp.com',
    databaseURL: 'https://urgent-care-ec38b.firebaseio.com',
    projectId: 'urgent-care-ec38b',
    storageBucket: 'urgent-care-ec38b.appspot.com',
    messagingSenderId: '414830734605',
    appId: '1:414830734605:web:1cf110fd4824c12f571444',
    measurementId: 'G-PTKLHWMTHF',
  },
  baseUrl: 'https://us-central1-urgent-care-ec38b.cloudfunctions.net',
  algolia: {
    searchKey: '70b9b799f3e6bc5b1a57253b55146312',
    appId: 'OES54S31SO',
  },
  emailUrl:'https://kenoshaurgicare.com/login'
};
