import * as yup from 'yup';

const schema = yup.object({
  race: yup.string().required('Race is a Required Field'),
  martialStatus: yup.string().required('Material Status is a Required Field'),
  prefaredLang: yup.string().required('Preferred Language is a Required Field'),
  homePhone: yup.string(),
  cellPhone: yup.string().required('Cell Phone is a Required Field'),
});

export default schema;
