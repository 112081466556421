import React from 'react';

import ReactPDF, { Text, View, StyleSheet } from '@react-pdf/renderer';
import { dateToMMDDYY } from '../../../utilities/firestoreDates';

export interface Props {
  invoiceNo: string;
  total: number;
  invoiceDate: Date;
  dueDate: Date;
  style?: ReactPDF.Style;
}

const styles = StyleSheet.create({
  labelTextWrapper: {
    flexDirection: 'row',
  },
  invoiceTitleFont: {
    fontSize: '24',
  },
  data: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
});

const InvoiceSummary: React.FC<Props> = ({
  invoiceNo,
  invoiceDate,
  dueDate,
  total,
  style,
}) => {
  return (
    <View style={style}>
      <View style={styles.labelTextWrapper}>
        <Text style={styles.invoiceTitleFont}>Invoice #</Text>
        <Text style={styles.invoiceTitleFont}>{invoiceNo}</Text>
      </View>
      <View style={styles.data}>
        <View style={styles.labelTextWrapper}>
          <Text style={{fontWeight:"bold"}}>Invoice Date: </Text>
          <Text>{dateToMMDDYY(invoiceDate)}</Text>
        </View>
        <View style={styles.labelTextWrapper}>
          <Text style={{fontWeight:"bold"}}>Due Date: </Text>
          <Text>{dateToMMDDYY(dueDate)}</Text>
        </View>
        <View style={styles.labelTextWrapper}>
          <Text style={{fontWeight:"bold"}}>Total Due: </Text>
          <Text>{`$${total.toFixed(2)}`}</Text>
        </View>
      </View>
    </View>
  );
};

export default InvoiceSummary;
