import React from 'react';
import { useAuth } from '../hooks';
import { Client } from '../models';

interface UserContextType {
  user: Client | undefined;
  authStateLoading: boolean;
  role:string;
  isAuthenticated:boolean;
}

export const UserContext = React.createContext<UserContextType>({
  user: undefined,
  authStateLoading: true,
  role:'',
  isAuthenticated:false
});

const UserProvider: React.FC = ({ children }) => {
  const { user, authStateLoading ,role,isAuthenticated} = useAuth();

  const context: UserContextType = {
    user,
    authStateLoading,
    role,
    isAuthenticated
  };

  return (
      <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};

export default UserProvider;