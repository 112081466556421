import React from 'react';

import ReactPDF, { View, Image, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  logo: {
    width: 100,
    height: 'auto',
  },
});

export interface Props {
  img: string;
  style?: ReactPDF.Style;
}

const CompanyLogo: React.FC<Props> = ({ img, style }) => {
  return (
    <View style={style}>
      <Image style={styles.logo} src={img} />
    </View>
  );
};

export default CompanyLogo;
