import React from 'react';
import {Dialog} from '@material-ui/core';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';

interface Props {
  showDialog: boolean;
  handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        left: '240px  !important',
        [theme.breakpoints.down('sm')]: {
          left: '0 !important',
        },
      },
      dialogPaperWidthSm: {
        maxWidth: '800px',
        height: '85vh',
        overflowX: 'hidden',
        minWidth: '70vw',
      },
      backdrop: {
        left: '240px !important',
        [theme.breakpoints.down('sm')]: {
          left: '0 !important',
        },
      },
    }),
);

const DashboardDialog: React.FC<Props> = ({
  showDialog,
  handleClose,
  children,
}) => {
  const classes = useStyles();

  return (
      <Dialog
          open={showDialog}
          onClose={handleClose}
          classes={{
            root: classes.root,
            paperWidthSm: classes.dialogPaperWidthSm,
          }}
          BackdropProps={{
            classes: {root: classes.backdrop},
          }}
      >
        {children}
      </Dialog>
  );
};

export default DashboardDialog;
