export const formatSSN = (SSN: string) => {
    const number = SSN.split('-').join('');
    if (isNaN(Number(number))) {
        return '';
    }
    if (number.length <= 3) {
        return number;
    } else if (number.length <= 5) {
        return number.slice(0, 3) + '-' + number.slice(3, 5);
    } else
        return (
            number.slice(0, 3) + '-' + number.slice(3, 5) + '-' + number.slice(5, 9)
        );
};
