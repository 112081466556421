import React, {useContext, useState} from 'react';
import {AppBar, IconButton, Typography, Toolbar} from '@material-ui/core';
import {Menu as MenuIcon} from '@material-ui/icons';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useLocation} from 'react-router-dom';

import MobileDrawer from '../MobileDrawer';
import {drawerWidth} from '../DrawerContent';
import UserAvatar from '../UserAvatar/index';
import {UserContext} from '../../context/UserProvider';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: '#fff',
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
      appBarWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
      menuIcon: {
        [theme.breakpoints.up('sm')]: {
          display: 'none',
        },
        [theme.breakpoints.down('sm')]: {
          display: 'block',
        },
      },
      toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      userControl: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#A01414',
      }
    }),
);

const slicePageName = (pathname: string) => {

  if (pathname.split('/')[2] == 'appointments') {
    return 'Appointments';
  } else if (pathname.split('/')[2] == 'OnlineHours') {
    return 'Online Hours';
  } else if (pathname.split('/')[2] == 'patient') {
    return 'Patients';
  } else if (pathname.split('/')[2] == 'PatientProfile') {
    return 'Patient Profile';
  } else if (pathname.split('/')[3] == 'GenerateInvoices') {
    return 'Add Invoice';
  } else if (pathname.split('/')[2] == 'ViewInvoices') {
    return 'View Invoices';
  } else if (pathname.split('/')[2] == 'Invoices') {
    return 'Invoices';
  } else if (pathname.split('/')[2] == 'Admins') {
    return 'Admins';
  } else{
    return '';
  }

};

const ApplicationBar: React.FC = () => {
  const {pathname} = useLocation();

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const classes = useStyles();
  const {user} = useContext(UserContext);

  const handleDrawerClose = () => setShowMobileMenu(false);

  return (
      <>
        <div className={classes.appBarWrapper}>
          <AppBar
              className={classes.root}
              position="static"
              elevation={undefined}
          >
            <Toolbar className={classes.toolbar}>
              <IconButton
                  className={classes.menuIcon}
                  onClick={() => setShowMobileMenu(c => !c)}
                  edge="start"
                  color="primary"
                  aria-label="menu"
              >

                <MenuIcon/>
              </IconButton>
              <Typography variant="h6" color="primary">
                {slicePageName(pathname)}
              </Typography>
              <div className={classes.userControl}>
                <UserAvatar userName={user?.name}/>
              </div>
            </Toolbar>
          </AppBar>
        </div>
        <MobileDrawer open={showMobileMenu} onClose={handleDrawerClose}/>
      </>
  );
};

export default ApplicationBar;
