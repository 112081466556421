import React from 'react';
import {Switch, useLocation, Redirect, Route} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';

import UserProvider from './context/UserProvider';
import { NoAuthRoute, PrivateRoute, PublicRoute } from './components/Routes';
import UserDashboard from './containers/UserDashboard';
import theme from './theme';
import NotAuth from './containers/NoAuth';
import Login from './containers/Login';

import FirebaseUtil from './config/firebase';
import { useIdleTimer } from 'react-idle-timer'
import './index.css';


const App: React.FC = () => {
  if (process.env.NODE_ENV !== 'production') {
    (window as any).FirebaseUtil = FirebaseUtil;
  }

  const { pathname } = useLocation();

  const handleOnIdle = () => {
    FirebaseUtil.signOut()
  }

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
  })

  return (
    <div className="App">
      <SnackbarProvider maxSnack={1}>
        <UserProvider>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Switch>
                <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                <NoAuthRoute exact path="/login" component={Login} />
                <PrivateRoute path="/dashboard" component={UserDashboard} />
                {/*<PrivateRoute exact path="/" component={UserDashboard} />*/}
                <PublicRoute exact path="/not-authorized" component={NotAuth} />
                <Route exact path={"/"}>
                  <Redirect to={"/dashboard"}/>
                </Route>
              </Switch>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </UserProvider>
      </SnackbarProvider>
    </div>
  );
};

export default App;
