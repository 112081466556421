const { config } = require(process.env.REACT_APP_ENV? `./${process.env.REACT_APP_ENV}.ts`: './development.ts');

type Config = {
  firebase: object;
  baseUrl: string;
  algolia: {
    searchKey: string;
    appId: string;
  };
  emailUrl:string;
};

export default config as Config;