import React from 'react';
import {Field, FormikErrors, FormikTouched, FormikValues} from 'formik';
import {MenuItem, Grid, Theme} from '@material-ui/core';
import {createStyles, makeStyles} from '@material-ui/core/styles';

import CustomInput from '../../../components/CustomInput';
import {CustomSelect} from '../../../components';
import {PatientStepFormValues} from '../index';
import {formatSSN} from '../../../utilities/formatSSN';

interface Props {
     values: FormikValues;
     errors: FormikErrors<PatientStepFormValues>;
     touched: FormikTouched<PatientStepFormValues>;
     setFieldValue: any;
     setEmail: any;
     patientId: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
        MUILimitation: {
             padding: '40px',
        },
        submitBtnWrapper: {
             display: 'flex',
             justifyContent: 'flex-end !important',
             [theme.breakpoints.down('xs')]: {
                  justifyContent: 'center !important',
             },
        },
        input: {
             minHeight: '82px',
        },
        firstContainer: {
             [theme.breakpoints.down('sm')]: {
                  paddingBottom: '0 !important',
             },
             [theme.breakpoints.down('xs')]: {
                  padding: '0 !important',
             },
        },
        secondContainer: {
             [theme.breakpoints.down('xs')]: {
                  padding: '0 !important',
             },
        },
        selectInput: {
             border: '1px solid #ccc',
             borderRadius: '3px',
        },
        selectInputWrapper: {
             minHeight: '92px',
        },
   }),
);

const UserDataForm: React.FC<Props> = ({
     values,
     touched,
     errors,
     setFieldValue,
     setEmail,
     patientId,
}) => {
     const classes = useStyles();

     return (
        <div>
             <div className={classes.MUILimitation}>
                  <Grid container spacing={5}>
                       <Grid item xs={12} sm={6} className={classes.firstContainer}>
                            <Grid container spacing={1}>
                                 <Grid item xs={12}>
                                      <Field
                                         fullWidth
                                         className={classes.input}
                                         name="firstName"
                                         as={CustomInput}
                                         label="First Name *"
                                         variant="filled"
                                         error={touched.firstName && errors.firstName && true}
                                         helperText={touched.firstName && errors.firstName}
                                      />
                                 </Grid>
                                 <Grid item xs={12}>
                                      <Field
                                         fullWidth
                                         className={classes.input}
                                         name="middleName"
                                         as={CustomInput}
                                         label="Middle Initial"
                                         variant="filled"
                                         error={touched.middleName && errors.middleName && true}
                                         helperText={touched.middleName && errors.middleName}
                                      />
                                 </Grid>
                                 <Grid item xs={12}>
                                      <Field
                                         fullWidth
                                         className={classes.input}
                                         name="lastName"
                                         as={CustomInput}
                                         label="Last Name *"
                                         variant="filled"
                                         error={touched.lastName && errors.lastName && true}
                                         helperText={touched.lastName && errors.lastName}
                                      />
                                 </Grid>
                            </Grid>
                            {!patientId &&
                            <Field
                                fullWidth
                                className={classes.input}
                                name="email"
                                value={values.email}
                                onChange={(e: any) => {
                                     const {value} = e.target;
                                     setFieldValue('email', value);
                                     setEmail(value);
                                }}
                                as={CustomInput}
                                label="Email *"
                                variant="filled"
                                error={touched.email && errors.email && true}
                                helperText={touched.email && errors.email}
                            />
                            }

                       </Grid>
                       <Grid item xs={12} sm={6} className={classes.secondContainer}>
                            <Grid container spacing={1}>
                                 <Grid item xs={12}>
                                      <Field
                                         fullWidth
                                         className={classes.input}
                                         name="birthDate"
                                         as={CustomInput}

                                         variant="filled"
                                         label="Date of birth *"
                                         type="date"
                                         InputLabelProps={{
                                              shrink: true,
                                         }}
                                         error={touched.birthDate && errors.birthDate && true}
                                         helperText={touched.birthDate && errors.birthDate}
                                      />
                                 </Grid>
                                 <Grid item xs={12} className={classes.selectInputWrapper}>
                                      <Field
                                         fullWidth
                                         name="gender"
                                         type="select"
                                         label="Gender *"
                                         as={CustomSelect}
                                         error={touched.gender && errors.gender && true}
                                         helperText={touched.gender && errors.gender}
                                      >
                                           <MenuItem value="male">Male</MenuItem>
                                           <MenuItem value="female">Female</MenuItem>
                                           <MenuItem value="other">Other</MenuItem>
                                      </Field>
                                 </Grid>
                                 <Grid item xs={12}>
                                      <Field
                                         value={values.SSN}
                                         onChange={(e: any) => {
                                              const {value} = e.target;
                                              setFieldValue('SSN', formatSSN(value));
                                         }}
                                         fullWidth
                                         className={classes.input}
                                         name="SSN"
                                         as={CustomInput}
                                         label="SSN"
                                         variant="filled"
                                         error={touched.SSN && errors.SSN && true}
                                         helperText={touched.SSN && errors.SSN}
                                      />
                                 </Grid>
                            </Grid>
                       </Grid>
                  </Grid>
             </div>
        </div>
     );
};

export default UserDataForm;
