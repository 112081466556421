import React from 'react';
import { Drawer, Theme, Hidden, DrawerProps } from '@material-ui/core';

import DrawerContent from '../DrawerContent';

import { makeStyles, createStyles } from '@material-ui/core/styles';

interface Props extends DrawerProps {
  open?: boolean;
}

export const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      backgroundColor: theme.palette.primary.main,
      width: drawerWidth,
    },
  }),
);

const MobileDrawer: React.FC<Props> = ({ open = false, onClose }) => {
  const classes = useStyles();
  return (
    <Hidden xsDown implementation="css">
      <Drawer
        variant="temporary"
        open={open}
        classes={{ paper: classes.drawer }}
        onClose={onClose}
      >
        <DrawerContent />
      </Drawer>
    </Hidden>
  );
};

export default MobileDrawer;
