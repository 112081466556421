import React, { useState } from 'react';
import {
  makeStyles,
  createStyles,
  ListItem,
  ListItemText,
  List,
  ListItemIcon,
} from '@material-ui/core';
import clsx from 'clsx';
import logo1 from '../../Images/logo1.png';
import { useHistory } from 'react-router-dom';
import {
  ADMIN,
  INVOICES,
  ONLINE,
  PATIENT,
  APPOITMENT,
  DASHBOARD,
} from '../../containers/UserDashboard/constants';

import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import FirebaseUtil from '../../config/firebase';
import ScheduleIcon from '@material-ui/icons/Schedule';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import LogoutIcon from '@material-ui/icons/ExitToAppOutlined';
import ReceiptIcon from '@material-ui/icons/Receipt';
interface Props {
  userAvatarUrl?: string;
}

interface DrawerItem {
  title: string;
  route: string;
  icon: any;
  current: boolean;
}

export const drawerWidth = 240;
const useStyles = makeStyles(() => {
  return createStyles({
    userCard: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '7vh',
    },
    textColor: {
      color: '#fff',
    },
    current: {
      color: '#fff',
      fontWeight: 'bold',
    },
    logoWrapper: {
      marginBottom: '2rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      cursor:'pointer'
    },
  });
});

const initDrawerData: DrawerItem[] = [
  {
    title: 'Appointments ',
    route: APPOITMENT,
    icon: ScheduleIcon,
    current: false,
  },
  {
    title: 'Online Hours ',
    route: ONLINE,
    icon: EventAvailableIcon,
    current: false,
  },
  {
      title: 'Invoices ',
      route: INVOICES,
      icon: ReceiptIcon,
      current: false,
  },
  {
    title: 'Patients ',
    route: PATIENT,
    icon: GroupIcon,
    current: false,
  },
  {
    title: 'Admins ',
    route: ADMIN,
    icon: PersonIcon,
    current: false,
  },
];

const DrawerContent: React.FC<Props> = () => {
  const classes = useStyles();
  const history = useHistory();

  const [drawerItems, setDrawerItems] = useState<DrawerItem[]>(initDrawerData);

  const handleClick = (ele: DrawerItem) => {
    setDrawerItems(
      drawerItems.map(i => {
        return { ...i, current: i.route === ele.route };
      }),
    );
    history.push(`${DASHBOARD}${ele.route}`);
  };

  return (
    <div className={classes.userCard}>
      <div className={classes.logoWrapper} onClick={()=>history.push('/dashboard/appointments')}>
        <img src={logo1} alt="Kenosha Logo" />
      </div>

      <List>
        {drawerItems.map(ele => (
          <ListItem
            button
            key={ele.title}
            className={clsx(classes.textColor, ele.current && classes.current)}
            onClick={() => handleClick(ele)}
          >
            <ListItemIcon>
              <ele.icon className={classes.textColor} />
            </ListItemIcon>

            <ListItemText
              classes={{ primary: ele.current ? classes.current : undefined }}
              primary={ele.title}
            />
          </ListItem>
        ))}
        <ListItem
          button
          key="logout"
          className={classes.textColor}
          onClick={() => FirebaseUtil.signOut()}
        >
          <ListItemIcon>
            <LogoutIcon className={classes.textColor} />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );
};

export default DrawerContent;
