import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import LoginForm from '../../components/LoginForm';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100vh',
    },
    content: {
      height: '100vh',
      paddingLeft: '9rem',
      background: '#F7F6F1',
    },
    showCaseContentWrapper: {
      height: 'fit-content',
      alignSelf: 'center',
      textAlign: 'left',
      zIndex: 1,
    },
  }),
);

const LoginPage: React.FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Grid className={classes.content} container justify="space-between">
        <Grid item xs className={classes.showCaseContentWrapper}>
          <LoginForm />
        </Grid>
        <Grid item container justify="flex-end" xs>
          <img
            width="120%"
            height="100%"
            src={require('../../Images/showcase.svg')}
            alt="doctors"
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default LoginPage;
