import React from 'react';
import { Text, View, StyleSheet ,} from '@react-pdf/renderer';

export interface Props {
  total: number;
}

const borderBottomColor = '#b95244';

const styles = StyleSheet.create({
  footer: {
    flexDirection: 'row',
    borderBottomColor: borderBottomColor,
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 40,
    textAlign: 'center',
    fontStyle: 'bold',
    marginBottom: 30,
  },
  label: {
    width: '50%',
    fontSize: 20,
  },
  total: {
    width: '50%',
    fontSize: 18,
  },
});

const InvoiceTableFooter: React.FC<Props> = ({ total }) => {
  return (
    <View style={styles.footer}>
      <Text style={styles.label}>TOTAL</Text>
      <View style={{width:"1px",backgroundColor:'#AE3431',height:'100%'}}/>
      <Text style={styles.total}>{`$${total.toFixed(2)}`}</Text>
    </View>
  );
};
export default InvoiceTableFooter;
