import React from 'react';
import { Avatar, AvatarProps,Typography } from '@material-ui/core';

interface Props extends AvatarProps {
  userName: string | undefined;
  avatarUrl?: string;
}

const UserAvatar: React.FC<Props> = ({ userName, avatarUrl, ...rest }) => {
  return (
      <div style={{display:'flex',alignItems:'center'}}>
        <Typography variant="h6" color="primary">
          {userName}
        </Typography>
        <div style={{width:'15px'}}/>
        <Avatar alt={userName} src={avatarUrl} {...rest}>
          {!avatarUrl && userName?.slice(0, 1)}
        </Avatar>
      </div>

  );
};

export default UserAvatar;
